// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState} from '../../../core/reducers/';
import { isLoggedIn, getLoggedInUser } from '../_selectors/auth.selectors';
import { MyAuthService } from '../../../core/auth';
import {constants} from "../../../app.constants";
import { SessiontimeoutUtil } from '../../_base/crud/index';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private store: Store<AppState>, 
        private router: Router, 
         private sessionTimeoutService: SessiontimeoutUtil
        ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
        return this.store
            .pipe(
                select(isLoggedIn), 
                tap(loggedIn => {
                    if (MyAuthService.isAuthenticated()) {
                        if (state.url === constants.pageUrl.login || state.url === constants.pageUrl.signup) {
                            console.log(state.url);

                          this.router.navigate([constants.pageUrl.home]);
                          
                        } else {
                            console.log(state.url);
                            this.sessionTimeoutService.onPageChange();
                            return true;
                      
                        }
                      }

                    // if (!loggedIn || !getLoggedInUser()) {
                    //     this.router.navigateByUrl('/auth/login');
                    // }
                    // else{
                    //     this.sessionTimeoutService.onPageChange();
                    //      return true;

                    // }
                    if (state.url === constants.pageUrl.login || state.url === constants.pageUrl.signup) {
                        return true;
                      } else {
                        this.router.navigate([constants.pageUrl.login]);
                      }
                })
            );
    }
}
