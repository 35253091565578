import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { constants } from "../../../../app.constants";
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from "../models/users.model";
// import { MyAuthService } from "../../../auth/index";
import { environment } from "../../../../../environments/environment";

@Injectable()
export class UserService {

  constructor(
    private http: HttpClient,
    private httpClient: HttpClient
    ) {
  }

  getAccountInfoByToken(token) {

    const url = constants.apiUrl.getAccountInfo;
    return this.httpClient.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept' : 'application/json',
        'Authorization': 'Bearer ' + token
      },
        observe: 'response'
      }).pipe(map((response) => {
      return response;
    }),
      catchError((error: any) => {
        return observableThrowError(error);
      }));
}
  getUserByDomain(domain: string) {
    let urls = environment.apiBaseUrl + constants.apiUrl.userByDomain + domain;
    return this.http.get(constants.apiUrl.userByDomain + domain)
      .pipe(map((response: any) => response.data),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  signup(user: User) {
    return this.http.post(constants.apiUrl.signup, user)
      .pipe(map((response) => {
        //this.authService.addLoginUserInLocalStorage(response);
        return response;
      }),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  saveAccountUpdate(userId: string, data: any): Observable<any> {
    const url = constants.apiUrl.updateUserAccount.replace('{userId}', userId);
    return this.http.put(url, JSON.stringify(data))
      .pipe(map((response) => response),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  updatePassword(userId: string, data: any): Observable<any> {
    const url = constants.apiUrl.updatePassword.replace('{userId}', userId);
    return this.http.patch(url, JSON.stringify(data))
      .pipe(map((response) => response),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  sendSupportMessage(userId: string, body) {
    const url = constants.apiUrl.sendSupportMessage.replace('{userId}', userId);
    return this.http.post(url, body);
  }

  getCurrentFundsByUserId(userId: string) {
    const url = constants.apiUrl.getCurrentFunds.replace('{userId}', userId);
    return this.http.get(url)
      .pipe(map((response: any) => response.data),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  getBillingDetailsByUserId(userId: string) {
    const url = constants.apiUrl.getBillingDetails.replace('{userId}', userId);
    return this.http.get(url)
      .pipe(map((response: any) => response.data),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  getParentBillingInfoByUserId(userId: string) {
    const url = constants.apiUrl.getParentBillingInfo.replace('{userId}', userId);
    return this.http.get(url)
      .pipe(map((response: any) => response.data),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  deleteAccountLogo(userId: string) {
    const url = constants.apiUrl.deleteAccountLogo.replace('{userId}', userId);
    return this.http.delete(url).pipe(map((response: any) => response.data),
      catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  checkoutWepay(loggedInUser: string, body: any) {
    const url = constants.apiUrl.checkoutWepayAccount.replace('{userId}', loggedInUser);
    return this.http.post(url, body);
  }

  getStripeKey(loggedInUser: string) {
    const url = constants.apiUrl.stripeKey.replace('{userId}', loggedInUser);
    return this.http.get(url);
  }

  chargeCardByStripe(loggedInUser: string, body: any) {
    const url = constants.apiUrl.stripeCharge.replace('{userId}', loggedInUser);
    return this.http.post(url, body);
  }

  geUserById(userId: string) {
    const url = constants.apiUrl.getUserById.replace('{userId}', userId);
    return this.http.get(url)
      .pipe(map((response: any) => response.data),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }
  // Defualt Get API KEY by User ID
   getRequestApiKeyByUserId(userId: string) {
     const url = constants.apiUrl.getRequestApiKeyByUserId.replace('{userId}', userId);
     return this.http.get(url);
   }
 
   // Defualt Get API KEY by POST User ID
   getRequestApiKeyByPostUserId(userId: string, body: any) {
     const url = constants.apiUrl.getRequestApiKeyByUserId.replace('{userId}', userId);
     return this.http.post(url, body);
   }
 
   // Get Account Info
   getAccountInfo() {
    const url = constants.apiUrl.getAccountInfo;
    return this.http.get(url);
  }

  // Update Account Info
  UpdateAccount(body: any) {
    const url = constants.apiUrl.getAccountInfo;
    return this.http.patch(url, body);
  }

// Get Balance Info
  getBalanceInfo(){
    const url = constants.apiUrl.getBalanceInfo;
    return this.http.get(url);
  }

  // Get Balance Info
  billingHistory(){
    const url = constants.apiUrl.billingHistory;
    return this.http.get(url);
  }

  getAssetPath(){
    return this.http.get(constants.dataKeys.assetsPath).pipe(map((response:any)=>{return response.data;}),
    catchError((error:any)=>{
return observableThrowError(error);
    }));
  }
}
