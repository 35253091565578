import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { constants } from "../../../../app.constants";
import { throwError as observableThrowError, Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { AppUtils } from "../../crud/index";

@Injectable()
export class SoundService {
  constructor(private http: HttpClient) { }

  getSoundList(options?): Observable<any> {
    let url = constants.apiUrl.getSoundList;
    url += "?page=" + options.currentPage + "&limit=" + options.pageSize;
    return this.http.get<any>(url);
  }

  getSystemMessage(id: any) {
    const url = constants.apiUrl.systemMessage
      .replace('{id}', id);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  saveSoundFileName(userId: string, soundFileId: string, body: any) {
    return this.http
      .patch(this._getSoundFileServiceUrl(userId, soundFileId), body)
      .pipe(
        map(response => response),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  deleteSoundFile(soundFileId: string) {
    let url = constants.apiUrl.getSoundList + "/" + soundFileId;
    return this.http
      .delete(url)
      .pipe(
        map(response => response),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  private _getSoundFileServiceUrl(userId: string, soundFileId: string) {
    return constants.apiUrl.soundFile
      .replace("{userId}", userId)
      .replace("{soundFileId}", soundFileId);
  }

  getSoundInfo(userId: string, soundFileId: string) {
    const url = this._getSoundFileServiceUrl(userId, soundFileId);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  textToSpeach(userId: string, payload: any) {
    const url = constants.apiUrl.textToSpeach.replace("{userId}", userId);
    payload["gender"] = Number(payload["gender"]);
    return this.http.post(url, payload).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

playTtsAudio(payload:any){
const url=constants.apiUrl.platTtsAudio
return this.http.post(url,payload).pipe(
map(response=>response),
catchError((error:any)=>{
  return observableThrowError(error)
})
);
}

  AddNewSound(file: File, fileName: any) {
    const url = constants.apiUrl.AddNewSound;
    let formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('name', fileName);

    // uploader:MultipartUploader = new MultipartUploader({url: URL});

    // let headers = new HttpHeaders();
    // /** In Angular 5, including the header Content-Type can invalidate your request */
    // headers.append('Content-Type', 'multipart/form-data');
    // headers.append('Accept', 'application/json');

    // return this.http.post(url, formData).pipe(
    //   map(response => response),
    //   catchError((error: any) => {
    //     return observableThrowError(error);
    //   })
    // );
    return this.http.post(url, formData);
  }

  getDefaultSound(userId: string) {
    const url = constants.apiUrl.getDefaultSound.replace("{userId}", userId);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }


  
// function to get selected sound object

 getSounds(audioId:string):Observable<any>{
  const url=constants.apiUrl.getSoundList+"/"+audioId
  return this.http.get(url);
  
  }
}
