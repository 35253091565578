import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constants } from "../../../../app.constants";
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from "../../../../../environments/environment";

@Injectable()
export class CommonService {

  constructor(private http: HttpClient) {
  }

  getFile(path: string): Observable<Blob> {
    return this.http.get(path, { responseType: 'blob', observe: 'response' }).pipe(map((response) => response.body));
  }

  getAssetPath() {
    let urls = environment.apiBaseUrl + constants.apiUrl.getAssetPath;
    // return this.http.get(constants.apiUrl.getAssetPath).pipe(map((response: any) => response.data));
    return this.http.get(urls).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }
}
