import { Subscription, forkJoin } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from "../environments/environment";
import { AuthNoticeService, AuthService, Login, MyAuthService } from './core/auth';
import { AppGlobal } from "./app.global";
import { constants } from "./app.constants";
import { AppUtils, EmitterUtil, WidgetUtil } from './core/_base/crud/index';

import { GmailLogin } from "./core/_base/layout/index";

// Layout
import { LayoutConfigService, 
	SplashScreenService, 
	TranslationService,
	UserService, 
	CommonService
} from './core/_base/layout';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as chLang } from './core/_config/i18n/ch';
import { locale as esLang } from './core/_config/i18n/es';
import { locale as jpLang } from './core/_config/i18n/jp';
import { locale as deLang } from './core/_config/i18n/de';
import { locale as frLang } from './core/_config/i18n/fr';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {

	private static snackBar: MatSnackBar;
	// Public properties
	title = 'Auto Dialer';
	isDataLoaded = false;
	loader: boolean;
	loading: Subscription;
	count = 0;
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor  
	 *
	 * @param translationService: TranslationService
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(
		private dialog: MatDialog,
		private translationService: TranslationService,
		private layoutConfigService: LayoutConfigService,
		private splashScreenService: SplashScreenService,
		private userService: UserService,
		private commonService: CommonService,
		private injector: Injector,
		private route: ActivatedRoute,
		private router: Router,
		private snack: MatSnackBar) {
			AppComponent.snackBar = snack;
			//	super(injector);
		// register translations
		this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
		let tokens = this.route.queryParams.subscribe(params => {			
			if (localStorage.getItem('loginAsUser')) {
				this.count += 1;
				localStorage.removeItem('loginAsUser');
				this.router.navigateByUrl('/rvmsas/dashboard');
			window.location.reload();
			}
		});
		
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		// enable/disable loader
		
		this.loader = this.layoutConfigService.getConfig('loader.enabled');

		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				this.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);

				// to display back the body content
				setTimeout(() => {
					document.body.classList.add('kt-page--loaded');
				}, 500);
			}
		});
		this.unsubscribe.push(routerSubscription);
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}

	public static openSnackBar(message: string, duration?:number) {
		AppComponent.snackBar.open(message, 'Close', {
		  duration: duration || 4000,
		});
	  }
	
	/**
	 * name
	 */
	public userSessionCheck(): void {
		if(!MyAuthService.getLoggedInUser()){
			WidgetUtil.showSessionTimeoutPopup(this.dialog);
			// AppComponent.openSnackBar('Sorry, Your Session Has Expired.')
			// setTimeout(() => {
			// 	this.router.navigateByUrl('/auth/login');
			// }, 1000);
		}
	}
}
