import * as $ from "jquery";

export class CustomUI {
  
  constructor(public settings: any) {
  }
  
  public generateCSS() {
    
    var style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = this.getButtonsCSS() +
                      this.getMenuCSS() +
                      this.getRadioButtonCSS() +
                      this.getCheckboxCSS() +
                      this.getLoaderCSS();
    
    document.getElementsByTagName('head')[0].appendChild(style);
  }
  
  private getButtonsCSS() {
    let primaryButtonCSS = '.btn { ' +
      'background: ' + this.settings.primaryColor + ";" +
      ' }';
  
    let primaryButtonHoverCSS = '.btn:hover, .btn:focus { ' +
        'background: ' + this.getColorShade(this.settings.primaryColor, 0.05) + ";" +
        'border-color: ' + this.settings.primaryColor + ";" +
      ' }';
  
    let secondaryButtonHoverCSS = '.btn-ghost:focus, .btn-ghost:hover { ' +
      'background: ' + this.settings.primaryColor + ";" +
      'border-color: ' + this.settings.primaryColor + ";" +
      ' }';
  
    let cmlGreenCSS = '.cml-green { ' +
      'background: ' + this.settings.primaryColor + " !important;" +
      ' }';
    
    return primaryButtonCSS + primaryButtonHoverCSS + secondaryButtonHoverCSS + cmlGreenCSS;
  }
  
  private getMenuCSS() {
    let menuItemActiveCSS = '.side-nav li.active > a { ' +
      '-webkit-box-shadow: inset 12px 0 0 ' + this.settings.primaryColor + ' !important;' +
      'box-shadow: inset 12px 0 0 ' + this.settings.primaryColor + ' !important;' +
      ' }';
    
    return menuItemActiveCSS;
  }
  
  private getRadioButtonCSS() {
    const radioColor = this.getColorShade(this.settings.primaryColor, -0.2);
    
    let RadioButtonCSS = '.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle { ' +
      'border-color: ' + radioColor + ";" +
      ' }' +
      
      '.mat-radio-button.mat-accent .mat-radio-inner-circle { ' +
      'background-color: ' + radioColor + ";" +
      ' }';
    
    return RadioButtonCSS;
  }
  
  private getCheckboxCSS() {
    const checkboxColor = this.getColorShade(this.settings.primaryColor, -0.2);
    
    let RadioButtonCSS = '.mat-checkbox-checked.mat-accent .mat-checkbox-background { ' +
      'background-color: ' + checkboxColor + ";" +
      ' }';
    
    return RadioButtonCSS;
  }
  
  private getLoaderCSS() {
    let loaderCSS = '.loader:after { ' +
      'border-color: ' + this.settings.primaryColor + " transparent transparent transparent;" +
      ' }';
    
    return loaderCSS;
  }
  
  private getColorShade(hex, lum) {
    
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }
    lum = lum || 0;
    
    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i*2,2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00"+c).substr(c.length);
    }
    
    return rgb;
  }
  
  public static loadSearchBar(parentId) {
      let submitIcon = $(parentId).find(".searchbox-icon");
      let inputBox = $(parentId).find(".searchbox-input");
      let searchBox = $(parentId).find(".searchbox");
      let isOpen = false;
    
      submitIcon.click(() => {
        if (isOpen == false) {
          searchBox.addClass("searchbox-open");
          inputBox.focus();
          isOpen = true;
        } else {
          if (inputBox.val() === "") {
            searchBox.removeClass("searchbox-open");
            inputBox.focusout();
            isOpen = false;
          }
        }
      });
    
      submitIcon.mouseup(() => {
        return false;
      });
      searchBox.mouseup(() => {
        return false;
      });
    
      $(document).mouseup(() => {
        if (isOpen == true) {
          submitIcon.click();
        }
      });
  }
}
