import { Component, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { AuthNoticeService, AuthService, Login, MyAuthService } from '../../../core/auth';
import { AppGlobal } from "../../../app.global";
import { constants } from "../../../app.constants";
import { GmailLogin } from "../../../core/_base/layout/index";
import { AppState } from '../../../core/reducers';
import { Store } from '@ngrx/store';
import { AppComponent } from '../../../app.component';
import { 
  LayoutConfigService, 
	SplashScreenService, 
	TranslationService,
	UserService, 
	CommonService
} from '../../../core/_base/layout';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'kt-loginas',
  templateUrl: './loginas.component.html',
  styleUrls: ['./loginas.component.scss']
})
export class LoginasComponent implements OnInit {
  loader: boolean = false;
  loginAsUser: any;
  assetsHome: any;
  logoPath: any;
  constructor(
    private cdr:ChangeDetectorRef,
    private userService: UserService,
    private myAuthService: MyAuthService,
    private commonService: CommonService,
    private injector: Injector,
    private store: Store<AppState>,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private snack: MatSnackBar,
    private layoutConfigService:LayoutConfigService
  ) { 
    this.loader = true;
    if(window.location.hostname == 'localhost'){
			this.setLogoPath("portal.rvm.sas2.co");
		}
		else{
			this.setLogoPath(window.location.hostname);
		}
    let tokens = this.route.queryParams.subscribe(params => {
			this.loginAsUser = params['token'];
    
			if (this.loginAsUser) {
				this.loginAsuserVerification(this.loginAsUser);
			}
		});
  }

  ngOnInit() {
  }
  loginAsuserVerification(token) {
		this.userService.getAccountInfoByToken(token).subscribe((response: any) => {
		  if (response) {
        this.loader = false;
			  this.store.dispatch(new Login({authToken: response.accessToken}));
			  this.myAuthService.addLoginUserInLocalStorage(response);
				AppComponent.openSnackBar(response.body.submessage);
				localStorage.setItem('loginAsUser', 'true');
				this.router.navigateByUrl('/rvmsas/dashboard');
		  }
      this.assetsPath();
		},
		  (error) => {
        this.loader = false;
			  AppComponent.openSnackBar(error.body.submessage);
        setTimeout(() => {
          this.router.navigateByUrl('/auth/login');
        }, 1000);
		  });
	  }

    setLogoPath(hostName){
      this.myAuthService.getAssetPath().subscribe((res: any) => {
        if(res){
      //    this.assetsHome = res.data.name;
        localStorage.setItem('assethome', res.data.name);
     
        }
      },(error:any) => {
        console.log(error)
      });
      console.log(this.assetsHome);
      this.myAuthService.getUserByDomain(hostName).subscribe((response: any) => {
        if (response && response.customize.logoPath != '' ) {
          this.cdr.detectChanges();
          this.logoPath = localStorage.getItem('assethome')+response.customize.logoPath;
          localStorage.setItem('logoPath',this.logoPath);
          this.layoutConfigService.setConfig({self: {logo: this.logoPath}}, true);
          this.cdr.detectChanges();
        }else {
          AppComponent.openSnackBar("Failed to load logo path from domain");
        //	this.logoPath = "./assets/media/logos/logo.png";
        }
      }, (error: any) => {
      //	this.logoPath = "./assets/media/logos/logo.png";
        AppComponent.openSnackBar("Enable to load domain:"+ error);
      });
    }

    assetsPath(){
      this.userService.getAssetPath().subscribe((res:any)=>{
      let assetPath = res.name
      localStorage.setItem('assetsPath', assetPath);
      })
    }
}
