import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constants } from "../../../../app.constants";
import { throwError as observableThrowError, Observable, of } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
// Model


@Injectable()
export class ReportService {

  constructor(private http: HttpClient) { }

  // Get Call Report List
  getCrdList(options) {
    const url = constants.apiUrl.SearchCRDList
      .replace("{page}", "" + options.currentPage)
      .replace("{limit}", "" + options.pageSize)
      .replace("{from}", "" + options.from)
      .replace("{to}", "" + options.to)
      .replace("{amdstatus}", "" + options.AMDStatus)
      .replace("{callstatus}", "" + options.callStatus)
      .replace("{campaignId}", "" + options.campaignId)
      .replace("{calltype}", "" + options.CallType)
      .replace("{callTo}",""+options.CalleeId);;
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

      // Get Call Report Daily List
  getCdrStatsDailyList(options) {

    const url = constants.apiUrl.CdrStatsDaily
      .replace("{page}", "" + options.currentPage)
      .replace("{limit}", "" + options.pageSize)
      .replace("{from}", "" + options.from)
      .replace("{to}", "" + options.to)
      .replace("{amdstatus}", "" + options.AMDStatus)
      .replace("{callstatus}", "" + options.callStatus)
      .replace("{campaignId}", "" + options.campaignId)
      .replace("{calltype}", "" + options.CallType);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

      // Get Call Report Hour Aleg List
  getCdrstatsHourlyAlegList(options) {
    const url = constants.apiUrl.CdrstatsHourlyAleg
      .replace("{page}", "" + options.currentPage)
      .replace("{limit}", "" + options.pageSize)
      .replace("{from}", "" + options.from)
      .replace("{to}", "" + options.to)
      .replace("{amdstatus}", "" + options.AMDStatus)
      .replace("{callstatus}", "" + options.callStatus)
      .replace("{campaignId}", "" + options.campaignId)
      .replace("{calltype}", "" + options.CallType);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

      // Get Call Report Hour Bleg List
  getCdrstatsHourlyBlegList(options) {
    const url = constants.apiUrl.CdrstatsHourlyBleg
      .replace("{page}", "" + options.currentPage)
      .replace("{limit}", "" + options.pageSize)
      .replace("{from}", "" + options.from)
      .replace("{to}", "" + options.to)
      .replace("{amdstatus}", "" + options.AMDStatus)
      .replace("{callstatus}", "" + options.callStatus)
      .replace("{campaignId}", "" + options.campaignId)
      .replace("{calltype}", "" + options.CallType);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  getCompareCampaignList(options) {
    const url = constants.apiUrl.campaigncompare;
    return this.http.post(url, {id: options}).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  getHourlyCampaignList(date, id) {
    const url = constants.apiUrl.campaigncomparehourly
      .replace("{from}", "" + date)
      .replace("{campaignId}", "" + id);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  getDailyCampaignList(date, id) {
    const url = constants.apiUrl.campaigncomparedaily
    .replace("{from}", "" + date)
    .replace("{campaignId}", "" + id);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

	exportReport(from, to, amdStatus, callStatus, campaignId: string, calltype, email,multipleemails) {
		const url = constants.apiUrl.exportReportData
			.replace("{from}", from)
			.replace("{to}", "" + to)
			.replace("{amdstatus}", amdStatus)
			.replace("{callstatus}", "" + callStatus)
			.replace("{campaignId}", campaignId)
			.replace("{calltype}", "" + calltype)
      .replace("{multipleemails}",""+multipleemails)

		let body = {"email": email}
		return this.http.post(url, body).pipe(
			map(response => response),
			catchError((error: any) => {
				return observableThrowError(error);
			})
		);
	}

  searchCallReportList(options){
    const url = constants.apiUrl.SearchCRDList
      .replace("{page}", "" + options.currentPage)
      .replace("{limit}", "" + options.pageSize)
      .replace("{from}", "" + options.from)
      .replace("{to}", "" + options.to)
      .replace("{amdstatus}", "" + options.AMDStatus)
      .replace("{callstatus}", "" + options.callStatus)
      .replace("{campaignId}", "" + options.campaignId)
      .replace("{calltype}", "" + options.CallType);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }
}
