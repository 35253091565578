import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { constants } from "../../../../app.constants";
import { AppUtils } from "../../../../core/_base/crud/utils/app.utils";

@Injectable()
export class TTSListService {
    constructor(private http: HttpClient) { }

    getttsList(options) {
        let url = constants.apiUrl.getTtsList;
        if (!AppUtils.isUndefinedOrNull(options)) {
            url += `&page=${options.currentPage}&limit=${options.pageSize}&type=2&listAll=${options.listAll}`;
        }
        return this.http.get(url)
            .pipe(map((response) => response),
                catchError((error: any) => {
                    return observableThrowError(error);
                }));
    }

    deleteMultipleTtsList(data: any) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: data,
        };
        let url = constants.apiUrl.deleteTTSList;
        return this.http.delete(url, options)
            .pipe(map((response) => response),
                catchError((error: any) => {
                    return observableThrowError(error);
                }));
    }


    saveCustomField(data: any) {
        let url = constants.apiUrl.customField;
        return this.http.post(url, data, { observe: 'response' })
            .pipe(
                map(response => response),
                catchError((error: any) => {
                    return observableThrowError(error);
                })
            );
    }

    getCustomFields() {
       let url = constants.apiUrl.customField;
       return this.http.get(url)
           .pipe(map((response) => response),
               catchError((error: any) => {
                   return observableThrowError(error);
               }));
   }

    importFileData(data: any) {
        let url = constants.apiUrl.importFileData;
        return this.http.post(url, data, { observe: 'response' })
            .pipe(
                map(response => response),
                catchError((error: any) => {
                    return observableThrowError(error);
                })
            );
    }
}