import { AppGlobal } from './../../../app.global';
import { Injectable, NgZone } from "@angular/core";
import { throwError as observableThrowError, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';

import { JwtHelperService } from "@auth0/angular-jwt";
import { constants } from "../../../app.constants";
import { LoginUser } from "../../_base/layout/index";
import { GoogleLoginService } from "../../_base/layout/services/google-login.service";
import { AppUtils } from "../../_base/crud/utils/app.utils";
import { EmitterUtil } from "../../_base/crud/utils/emitter.util";
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
// Layout



@Injectable()
export class MyAuthService {

  public loginUser: LoginUser;
  public jwtHelper: JwtHelperService = new JwtHelperService();
  baseUrl = environment.apiBaseUrl;

  /********************
   * STATIC ACCESS *****
   *********************/
  public static logout(): boolean {
    if (this.isLoginFromGoogle()) {

      const googleLoginService = AppGlobal.injector.get(GoogleLoginService);
      const zone = AppGlobal.injector.get(NgZone);

      googleLoginService.logout(() => {
        zone.run(() => {
          return MyAuthService._logout();
        });
      });

    } else {
      return MyAuthService._logout();
    }
  }

  public static _logout() {
    if (localStorage.getItem(constants.localStorageUserLoginKey)) {
      localStorage.removeItem(constants.localStorageUserLoginKey);
      EmitterUtil.get(constants.events.navigation).emit(constants.pageUrl.login);
      return true;
    }
    return false;
  }

  public static isAuthenticated(): boolean {

    const loginUser = MyAuthService.getLoginUser();
    if (loginUser) {
      const jwtHelper = new JwtHelperService();
      const token = loginUser.token;
      if (!loginUser.token || jwtHelper.isTokenExpired(token)) {
        MyAuthService.logout();
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  public getUserByDomain(hostName) {
    let urls = constants.apiUrl.getDomainUser.replace('{hostName}', hostName);
    return this.http.get(urls)
      .pipe(map((response: any) => response.data),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  public static getTokenExpiry(): number {
    const loginUser = MyAuthService.getLoginUser();
    let date: number;
    if (loginUser) {
      const jwtHelper = new JwtHelperService();
      const token = loginUser.token;
      date = jwtHelper.getTokenExpirationDate(token).valueOf();
      return date;
    }
    date = new Date(0).valueOf();
    return date;
  }

  public static getToken(): string {

    if (MyAuthService.isAuthenticated()) {
      return MyAuthService.getLoginUser().token;
    }

    return '';
  }

  public static isLoginFromGoogle(): boolean {

    const loginUser = MyAuthService.getLoginUser();
    if (!AppUtils.isUndefinedOrNull(loginUser)) {
      return loginUser.googleLogin;
    }

    return false;
  }

  public static updateToken(token: string): string {

    if (token !== null) {
      if (MyAuthService.isAuthenticated()) {
        const loginUser = MyAuthService.getLoginUser();
        loginUser.token = token;
        localStorage.setItem(constants.localStorageUserLoginKey, JSON.stringify(loginUser));
        return MyAuthService.getLoginUser().token;
      }
    }
  }

  /**************************
   * HELPER FUNCTIONS ********
   **************************/
  private static getLoginUser() {
    const storedLoginUser = JSON.parse(localStorage.getItem(constants.localStorageUserLoginKey));
    return storedLoginUser ? storedLoginUser : null;
  }

  public static getLoggedInUser() {

    const storedLoginUser = JSON.parse(localStorage.getItem(constants.localStorageUserLoginKey));
    return storedLoginUser ? storedLoginUser : null;
  }

  public static getLoggedInUserId() {
    const loggedInUser = this.getLoggedInUser();
    return loggedInUser.id;
  }

  private buildLoginUser(token: any, data: any): LoginUser {
    data = data.data;
    const decodedToken: any = this.jwtHelper.decodeToken(token);
    const loginUser = new LoginUser(
      decodedToken.id,
      decodedToken.version,
      decodedToken.firstName,
      decodedToken.lastName,
      data.companyName,
      decodedToken.email,
      data.phone,
      decodedToken.exp,
      token,
      (data.gmailId) ? true : false,
      data.role,
      {},
      data.telephonicId,
      data.telephonicCode,
      data.parentid,
      data.customize,
      data.timesetting,
	  data.account
    );

    return loginUser;
  }

  constructor(private http: HttpClient) {
    this.loginUser = MyAuthService.getLoginUser();
  }

  login(data: any) {

    const url = constants.apiUrl.login;
    return this.http.post(url, JSON.stringify(data), { observe: 'response' })
      .pipe(map((response) => {
        this.addLoginUserInLocalStorage(response);
        return response;
      }),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  addLoginUserInLocalStorage(response) {
    const headers = response.headers;
    const token = headers.get(constants.apiRequestHeaderKeys.authToken);

    const loggedInContact = this.buildLoginUser(token, response.body);
    localStorage.setItem(constants.localStorageUserLoginKey, JSON.stringify(loggedInContact));
  }

  getAssetPath() {
    let urls = constants.apiUrl.getAssetPath;
    return this.http.get(constants.apiUrl.getAssetPath)
      .pipe(map((response) => {
        return response;
      }),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  public static updateUserInfo(data: any) {
    let loggedInContact: LoginUser = MyAuthService.getLoggedInUser();
    loggedInContact.phone = data.phone;
    loggedInContact.firstName = data.firstName;
    loggedInContact.lastName = data.lastName;
    loggedInContact.companyName = data.companyName;
    loggedInContact.customize = data.customize;

    localStorage.setItem(constants.localStorageUserLoginKey, JSON.stringify(loggedInContact));
  }

  updateToken() {
    return this.http.get(constants.apiUrl.refreshToken)
      .pipe(map((response) => {
        return response;
      }),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  sendForgot(email: string) {
    const requestUrl = constants.apiUrl.sendForgot.replace('{email}', email);
    return this.http.get(requestUrl);
  }

  forgotPasswordVerify(token: string, password: string) {
    const requestUrl = constants.apiUrl.forgotPasswordVerify.replace('{password}', password).replace('{token}', token);
    return this.http.get(requestUrl, { observe: 'response' })
      .pipe(map((response) => {
        this.addLoginUserInLocalStorage(response);
        return response.body;
      }),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  googleLogin(data: any) {
    return this.http.post(constants.apiUrl.gmailLogin, JSON.stringify(data), { observe: 'response' })
      .pipe(map((response) => {

        const headers = response.headers;
        const token = headers.get(constants.apiRequestHeaderKeys.authToken);

        //this.addLoginUserInLocalStorage(response);
        const loggedInContact = this.buildLoginUser(token, response.body);
        localStorage.setItem(constants.localStorageUserLoginKey, JSON.stringify(loggedInContact));
        return loggedInContact;
      }),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  resendVerify(userId: string) {
    const url = constants.apiUrl.resendVerify.replace('{userId}', userId);
    return this.http.get(url)
      .pipe(map((response) => {
        return response;
      }),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  VerifyUser(tokenId: string, password: string) {
    const url = constants.apiUrl.verify.replace('{token}', tokenId).replace('{password}', password);
    return this.http.get(url, { observe: 'response' })
      .pipe(map((response) => {
        this.addLoginUserInLocalStorage(response);
        return response.body;
      }),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }

  confirmUser(tokenId: string) {
    const url = constants.apiUrl.confirm.replace('{token}', tokenId);
    return this.http.get(url, { observe: 'response' })
      .pipe(map((response) => {
        this.addLoginUserInLocalStorage(response);
        return response;
      }),
        catchError((error: any) => {
          return observableThrowError(error);
        }));
  }
}
