import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import * as _ from "underscore";
import * as $ from "jquery";
// import * as moment from 'moment';
import * as moment from "moment-timezone";
import 'moment-duration-format';

//import * as $ from 'jquery/dist/jquery.min.js';

@Injectable()
export class AppUtils {

  public static getError(response): string {
    let errorMessage = "Please try again!";
    if (!AppUtils.isUndefinedOrNull(response) && !AppUtils.isUndefinedOrNull(response.message)) {
      errorMessage = response.message;
      if (!AppUtils.isUndefinedOrNull(response.submessage)) {
        errorMessage = response.submessage + (response.submessage.endsWith(".") ? " " : ". ") + errorMessage;
      }
    }

    return errorMessage;
  }

  public static isUndefined(value): boolean {
    return _.isUndefined(value);
  }

  public static isUndefinedOrNull(value): boolean {
    return (_.isUndefined(value) || _.isNull(value) || value.length === 0 || _.isEmpty(value));
  }

  public static markAsDirty(group: FormGroup) {
    group.markAsDirty();
    for (const i in group.controls) {
      if (group.controls[i] instanceof FormControl) {
        group.controls[i].markAsDirty();
      } else if (group.controls[i] instanceof FormGroup) {
        AppUtils.markAsDirty(<FormGroup>group.controls[i]);
      }
    }
  }
  public static markAsUntouch(group: FormGroup) {
    group.markAsDirty();
    for (const i in group.controls) {
      if (group.controls[i] instanceof FormControl) {
        group.controls[i].markAsUntouched();
      } else if (group.controls[i] instanceof FormGroup) {
        AppUtils.markAsUntouch(<FormGroup>group.controls[i]);
      }
    }
  } public static markAsTouch(group: FormGroup) {
    group.markAsDirty();
    for (const i in group.controls) {
      if (group.controls[i] instanceof FormControl) {
        group.controls[i].markAsTouched();
      } else if (group.controls[i] instanceof FormGroup) {
        AppUtils.markAsTouch(<FormGroup>group.controls[i]);
      }
    }
  }

  public static deepCopy(to: Array<any>, from: Array<any>) {

    for (let i = 0; i < to.length; i++) {
      to.pop();
    }
    to.pop();
    for (let i = 0; i < from.length; i++) {
      to.push(from[i]);
    }
  }

  public static copyDataInForm(data, form) {
    Object.keys(data).forEach(name => {
      if (form.controls[name]) {
        form.controls[name].patchValue(data[name]);
      }
    });
  }

  public static enableForm(group: FormGroup, enable: boolean) {
    for (const i in group.controls) {
      if (enable) {
        group.controls[i].enable();
      } else {
        group.controls[i].disable();
      }
    }
  }

  public static copyObjectFields(newObject, oldObject) {
    Object.keys(newObject).forEach(name => {
      if (oldObject[name] || oldObject[name] == null) {
        oldObject[name] = newObject[name];
      }
    });
  }

  public static contains(criteriaName: string, array: any): boolean {
    let exists = false;

    if (!AppUtils.isUndefinedOrNull(array)) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].name === criteriaName) {
          exists = true;
          break;
        }
      }
    }

    return exists;
  }

  public static convertBytesInMB(sizeInBytes: number): number {
    return ((sizeInBytes / 1024) / 1024);
  }

  public static addAlphabaticsChars(charArray) {
    for (let i = 65; i <= 90; i++) {
      charArray.push(String.fromCharCode(i));
    }
  }

  public static convertArrayIntoString(array: string[]): string {
    let str = "";
    const delimeter = ", ";

    for (let i = 0; i < array.length; i++) {
      if (i === (array.length - 1)) {
        str += array[i];
      } else {
        str += array[i] + delimeter;
      }
    }

    return str;
  }

  public static scrollToElement(element: string, delay: number = 50) {
    setTimeout(() => {
      $('html, body').animate({
        scrollTop: $(element).offset().top - 80
      }, 500);
    }, delay);

  }

  public static convertSecondstoTime(seconds) {
    let duration: any = moment.duration(seconds, 'seconds');
    let formatted = duration.format("hh:mm:ss");
    if (formatted.length > 2)
      return formatted;
    else
      return '00:' + formatted;
  }
  public static convertSecondstoMSTime(seconds) {
    let duration: any = moment.duration(seconds, 'seconds');
    let formatted = duration.format("mm:ss");
    if (formatted.length > 2)
      return formatted;
    else
      return '00:' + formatted;
  }

  public static getFileExtension(fileName: string, addDot: boolean): string {
    return (addDot ? "." : "") + fileName.split('.').pop();
  }

  public static getPercent(value, totalInSeconds: any) {
    return ((value / totalInSeconds) * 100);
  }

  public static getPercentRoundedOf(value, totalInSeconds: any) {
    return ((value / totalInSeconds) * 100).toFixed(2);
  }

  public static deepCloneObject(objectToCopy) {
    return (JSON.parse(JSON.stringify(objectToCopy)));
  }

  public static getCurrentURI(): string {
    return window.location.href;
  }

  // public static getPSTDatetime() {
  //   // your inputs
  //   let input = moment();
  //   let fmt = "MM/DD/YYYY h:mm:ss";  // must match the input
  //   let zone = "America/Los_Angeles";
  //   // construct a moment object
  //   let m = moment.tz(input, fmt, zone);
  //   // format it for output
  //   let pst = m.format(fmt)
  //   return pst;
  // }

  // public static getPSTDatetimeWithDefualtEndTime() {

  //   // your inputs
  //   let input = moment();
  //   let fmt = "MM/DD/YYYY H:mm:ss";  // must match the input
  //   let zone = "America/Los_Angeles";
  //   // construct a moment object
  //   let m = moment.tz(input, fmt, zone);
  //   m = m.hours(23).minutes(59).seconds(59);
  //   // format it for output
  //   let pst = m.format(fmt)
  //   return pst;
  // }

  // public static getPSTDatetimeWithDefualtStartTime() {
  //   // your inputs
  //   let input = moment();
  //   let fmt = "MM/DD/YYYY H:mm:ss";  // must match the input
  //   let zone = "America/Los_Angeles";
  //   // construct a moment object
  //   let m = moment.tz(input, fmt, zone);
  //   m = m.hours(0).minutes(0).seconds(0);
  //   // format it for output
  //   let pst = m.format(fmt)
  //   return pst;
  // }

  public static getPSTStartDatetime() {
    // initialize a new moment object to midnight UTC of the current UTC day
    var m1 = moment(new Date());

    // set the time zone of the new object
    m1.tz('America/Los_Angeles');
    // set the time you desire, in UTC
    m1.hours(0).minutes(0).seconds(0);
    return m1.format('MM/DD/YYYY HH:mm:ss');
  }

  public static getPSTEndDatetime() {
    // initialize a new moment object to midnight UTC of the current UTC day
    var m2 = moment(new Date());
    // set the time zone of the new object
    m2.tz('America/Los_Angeles');
    // set the time you desire, in UTC
    m2.hours(23).minutes(59).seconds(0);
    return m2.format('MM/DD/YYYY HH:mm:ss');
  }

  public static convertPSTtoUTC(date) {
    // your inputs
    if (date) {
      var convertfor = moment(date).format('YYYY-MM-DD HH:mm:ss');
      var pstConvert = moment.tz(convertfor, 'America/Los_Angeles');
      // date = moment(date).format();
      // set the time zone of the new object
      //  let m2 = moment.tz(date, 'America/Los_Angeles');
      let utcDateTime = moment.utc(pstConvert).format();
      return utcDateTime;
    }
  }
  public static convertUTCtoPST(date) {
    // your inputs
    if (date) {
      var m2 = moment(date);
      //  m2.add(8, 'hours');
      // set the time zone of the new object
      m2.tz('America/Los_Angeles');
      let pstDateTime = m2.format('MM/DD/YYYY HH:mm:ss');
      //  m2.add(8, 'hours');
      return pstDateTime;
    }
  }
}
