import { FormControl, FormGroup } from "@angular/forms";
// import {AppGlobal} from "../../app.global";
import { constants } from "../../../../app.constants";

export class ValidatorUtil {

  public static emailValidator(control: FormControl): { [key: string]: any } {

    const emailRegexp = /^['_A-Za-z0-9-\+]+(\.['_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/i;

    if (control.value && !emailRegexp.test(control.value)) {
      return { invalidEmail: true };
    }
  }
  // public static cpmValidator(control: FormControl): { [key: string]: any } {
  //   const max = AppGlobal.getData(constants.dataKeys.userInfo) && AppGlobal.getData(constants.dataKeys.userInfo)!.limits.callLimit !== 0  ? AppGlobal.getData(constants.dataKeys.userInfo)!.limits.callLimit:50;

  //   if (Number(control.value) > max || Number(control.value) < 1) {
  //     return {invalidCPM: true};
  //   }
  // }
  public static usaNumberValidator(control: FormControl): { [key: string]: any } {
    const usaNumRegexp = new RegExp("^(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:\\(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*\\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?([0-9]{4})(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+))?$");
    if (control.value && !usaNumRegexp.test(control.value)) {
      return { invalidNumber: true };
    }
  }

  public static callerIdValidator(control: FormControl): { [key: string]: any } {
    // const callerIdRegexp = new RegExp('^\+?\d{10,11}(,\+?\d{10,11})*$');
    const callerIdRegexp = new RegExp(/^\+?\d{10,11}(,\+?\d{10,11})*$/i);
    if (control.value && !callerIdRegexp.test(control.value)) {
      return { invalidNumber: true };
    }
  }

  public static matchWithValidator(toControlName: string) {
    let ctrl: FormControl;
    let toCtrl: FormControl;
    return function matchWith(control: FormControl): { [key: string]: any } {

      if (!control.parent) {
        return null;
      }

      if (!ctrl) {
        ctrl = control;
        toCtrl = control.parent.get(toControlName) as FormControl;

        if (!toCtrl) {
          return null;
        }

        toCtrl.valueChanges.subscribe(() => {
          ctrl.updateValueAndValidity();
        });
      }

      if (ctrl.value !== "" && toCtrl.value !== ctrl.value) {
        return {
          matchWith: true
        };
      }
      return null;
    };
  }

  public static mustNotMatchWithValidator(toControlName: string) {
    let ctrl: FormControl;
    let toCtrl: FormControl;
    return function matchWith(control: FormControl): { [key: string]: any } {

      if (!control.parent) {
        return null;
      }

      if (!ctrl) {
        ctrl = control;
        toCtrl = control.parent.get(toControlName) as FormControl;

        if (!toCtrl) {
          return null;
        }

        toCtrl.valueChanges.subscribe(() => {
          ctrl.updateValueAndValidity();
        });
      }

      if (ctrl.value !== "" && toCtrl.value === ctrl.value) {
        return {
          mustNotMatchWith: true
        };
      }
      return null;
    };
  }
}
