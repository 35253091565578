// Angular
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginasComponent } from "./views/pages/loginas/loginas.component";

const routes: Routes = [
	{
		path: "auth",
		loadChildren: () =>
			import("../app/views/pages/auth/auth.module").then(
				(m) => m.AuthModule
			),
	},
	{
		path: "loginas",
		component: LoginasComponent,
	},

	/** START: remove this themes list on production */
	{ path: "", redirectTo: "rvmsas", pathMatch: "full" },
	{
		path: "rvmsas",
		loadChildren: () =>
			import("../app/views/themes/demo8/theme.module").then(
				(m) => m.ThemeModule
			),
	},
	{ path: "**", redirectTo: "rvmsas/error/403", pathMatch: "full" },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
