export const columnsMapping: Array<ColumnsMapping> = [
    {
        columnName: 'first_name',
        mappings: ['f_name', 'name', 'first_name', 'first name', 'First Name'],
        columnLabel: 'First Name',
        predefinedField: true
    },
    {
        columnName: 'last_name',
        mappings: ['l_name', 'name', 'last_name', 'last name', 'Last Name'],
        columnLabel: 'Last Name',
        predefinedField: true
    },
    {
        columnName: 'number',
        mappings: ['phone', 'number', 'contactinformation', 'contact information', 'contact_number', 'mobilenumber', 'phonenumber', 'phone_number', 'mobile_number', 'p_number'],
        columnLabel: 'Number',
        predefinedField: true
    },
    {
        columnName: 'address',
        mappings: ['address', 'area', 'city/country', 'address1', 'address2'],
        columnLabel: 'Address',
        predefinedField: true
    },
    {
        columnName: 'email',
        mappings: ['email', 'Email'],
        columnLabel: 'Email',
        predefinedField: true
    },
    {
        columnName: 'location',
        mappings: ['location', 'Location'],
        columnLabel: 'Location',
        predefinedField: true
    },
    {
        columnName: 'state',
        mappings: ['state'],
        columnLabel: 'State',
        predefinedField: true
    },
    {
        columnName: 'country',
        mappings: ['country'],
        columnLabel: 'Country',
        predefinedField: true
    },
    {
        columnName: 'city',
        mappings: ['city'],
        columnLabel: 'City',
        predefinedField: true
    }
];

interface ColumnsMapping {
    columnName: string;
    mappings: Array<string>;
    columnLabel: string;
    predefinedField: boolean;
}

export interface FileData {
    columns: Array<Column>,
    data: Array<any>
}

export interface Column {
    mappedColumnName: string;
    columnNameFromFile: string;
    mappedColumnLabel: string;
    columnIndex: number;
    showDropdown: boolean;
    showCustomField: boolean;
    columnSkipped: boolean;
    customField: boolean;
    customFieldText: string;
    options: Array<ColumnsMapping>;
}

export interface CustomFields {
    id?: string;
    name?: string;
    key?:string;
    column: number;
}

export interface FieldData {
    name_key: string;
    name: string;
}

export class FileMetaUtil {

    private static getMatchedPreDefinedColumns(propertyName: string, columnsMappings: Array<ColumnsMapping>): Array<ColumnsMapping> {
        const matchedPreDefinedFields = [];
        columnsMappings.forEach(columnMapping => {
            columnMapping.mappings.forEach(mapping => {
                if (mapping.toLowerCase() === propertyName.toLowerCase()) {
                    matchedPreDefinedFields.push(columnMapping);
                }
            });
        });
        return matchedPreDefinedFields;
    }

    private static getMatchedPreDefinedColumn(propertyName: string, columns: Array<Column>, columnsMappings: Array<ColumnsMapping>): ColumnsMapping {
        let matchedPreDefinedColumn: ColumnsMapping;
        const matchedPreDefinedFields = FileMetaUtil.getMatchedPreDefinedColumns(propertyName, columnsMappings);
        if (matchedPreDefinedFields && matchedPreDefinedFields.length > 0) {
            matchedPreDefinedFields.forEach(preDefinedColumn => {
                const index = columns.findIndex(x => x.mappedColumnName === preDefinedColumn.columnName && x.mappedColumnLabel === preDefinedColumn.columnLabel);
                if (index < 0) {
                    matchedPreDefinedColumn = preDefinedColumn;
                }
            });
        }
        return matchedPreDefinedColumn;
    }

    public static getFileData(rawFileData: Array<any>): FileData {
        if (rawFileData && rawFileData.length > 0) {
            // get first object of array to verify the columns name
            const firstRow = rawFileData[0] as any;
            const columns: Array<Column> = [];
            const data = [];
            // prepare columns array
            Object.keys(firstRow).forEach((key, index) => {
                const matchedPreDefinedColumn = FileMetaUtil.getMatchedPreDefinedColumn(key, columns, columnsMapping);
                const predefinedColumns = JSON.parse(JSON.stringify(columnsMapping)) as Array<ColumnsMapping>;
                // predefinedColumns.push({
                //     columnLabel: '+ Add Custom Field',
                //     columnName: 'addCustomField',
                //     mappings: [],
                //     predefinedField: false
                // }, {
                //     columnLabel: '--- Skip Column',
                //     columnName: 'skipColumn',
                //     mappings: [],
                //     predefinedField: false
                // });
                if (matchedPreDefinedColumn) {
                    columns.push({
                        columnIndex: index,
                        columnNameFromFile: key,
                        mappedColumnLabel: matchedPreDefinedColumn.columnLabel,
                        mappedColumnName: matchedPreDefinedColumn.columnName,
                        showDropdown: true,
                        showCustomField: false,
                        columnSkipped: false,
                        customField: false,
                        customFieldText: null,
                        options: predefinedColumns
                    });
                } else {
                    columns.push({
                        columnIndex: index,
                        columnNameFromFile: key,
                        mappedColumnLabel: '--- Skip Column',
                        mappedColumnName: 'skipColumn',
                        showDropdown: true,
                        showCustomField: false,
                        columnSkipped: true,
                        customField: false,
                        customFieldText: null,
                        options: predefinedColumns
                    });
                }
            });
            // prepare row Data
            rawFileData.forEach(row => {
                const rowData: any = {};
                Object.keys(row).forEach((key, index) => {
                    rowData[index] = row[key];
                });
                data.push(rowData);
            });
            const fileData: FileData = {
                columns: columns,
                data: data
            };
            return fileData;
        } else {
            return null;
        }
    }

    public static isDefinedAllColumns(columns: Array<Column>): boolean {
        let allDefine = true;
        columns.forEach(column => {
            if (!column.mappedColumnName && !column.columnSkipped && !column.customField && !column.customFieldText) {
                allDefine = false;
            }
        });
        return allDefine;
    }

    public static hasDuplicateColumns(columns: Array<Column>): boolean {
        let duplicateColumns = false;
        columns.forEach(column => {
            const matchedColumns = columns.filter(x => (x.mappedColumnName !== 'skipColumn' && x.mappedColumnLabel === column.mappedColumnLabel && x.mappedColumnName === column.mappedColumnName));
            if (matchedColumns.length > 1) {
                duplicateColumns = true;
            }
        });
        return duplicateColumns;
    }

    public static getFileMeta(columns: Array<Column>, customFields: Array<CustomFields>): any {
        const meta: any = {};
        columnsMapping.forEach(columnMapping => {
            meta[columnMapping.columnName] = columns.findIndex(x => x.mappedColumnName === columnMapping.columnName);
        });
        let allDefined = true;
        columns.forEach(column => {
            const index = columnsMapping.findIndex(x => x.columnName === column.mappedColumnName);
            if (index < 0) {
                allDefined = false;
            }
        });
        if (allDefined) {
            meta['customFields'] = [];
        } else {
            meta['customFields'] = customFields;
        }
        return meta;
    }

    public static validateCustomField(customField: string, columns: Array<Column>, customFields: Array<CustomFields>): string {
        let errorMessage = null;
        let index = columns.findIndex(x => x.mappedColumnLabel.trim().toLowerCase() === customField.trim().toLowerCase());
        if (index >= 0) {
            errorMessage = 'Already defined in predefined columns';
        } else {
            index = customFields.findIndex(x => x.name.trim().toLowerCase() === customField.trim().toLowerCase());
            if (index >= 0) {
                errorMessage = 'Already defined in custom fields';
            } else {
                let duplicate = false;
                columns.forEach(column => {
                    index = column.options.findIndex(x => x.columnName === customField);
                    if (index >= 0) {
                        duplicate = true;
                    }
                });
                if (duplicate) {
                    errorMessage = 'Already defined in columns';
                }
            }
        }
        return errorMessage;
    }
}