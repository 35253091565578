export const constants = {
  localStorageUserLoginKey: "uml-login",
  localStorageLanguageKey: "uml-Language",
  roleId: "599ae2df2768fe9371babc92",
  apiUrl: {
    getAssetPath: "/assethome",
    login: "/login",
    gmailLogin: "/gmailSignIn",
    consumerLogin: "/user/{userId}/customerLogin",
    refreshToken: "/refreshtoken",
    clientSideException: "sendClientSideException",
    sendForgot: "/sendforgot?email={email}",
    getDomainUser: "/domain?domain={hostName}",
    forgotPasswordVerify: "/forgotpasswordverify?token={token}&newPassword={password}",
    userByDomain: "/user?domain=",
    getAccountInfo: '/user',
    getBalanceInfo: '/user/currentfunds',
    billingHistory: '/billing/history',
    signup: "/user",
    getContactList: "/contactlist?",
    addContactList: "/contactlist",
    downloadContactList:"/contactlist/{contactlistid}",
    addDNCnumber:"/adddnc/{dncId}",
    getDncNumberList: "/dnc?number={number}&page={page}&limit={limit}",
    deleteDncNumberList: "/contactlistid/{contactlistid}?number={number}",
    deleteTtsNumberList: "/contactlistid/{contactlistid}?number={number}",
    getTtsNumberList: "/tts?number={number}&page={page}&limit={limit}",
    getTtsList: '/ttslist?',
    customField: '/customfield',
    importFileData: '/import',
    deleteTTSList: '/ttslist',
    telnyxSearch: '/telnyx/searchnumbers?country_code={country_code}&locality={locality}&administrative_area={administrative_area}&limit={limit}&npa={npa}',
    bulkNumberSearch: '/number/searchnumbers?npa={npa}',
    telnyxnumbers: '/telnyxnumbers?page={page}&limit={limit}',
    numbers: '/number?page={page}&limit={limit}',
    telnyxnumbersGroupGet: '/telnyxnumbersgroup?id={id}&page={page}&limit={limit}',
    numbersGroupGet: '/numbercarriergroup?id={id}&page={page}&limit={limit}',
    telnyxnumbersGroupContactGet: '/telnyxnumbersgroup/contactlist?id={id}&page={page}&limit={limit}',
    numbercarriergroupGroupContactGet: '/numbercarriergroup/contactlist?id={id}&page={page}&limit={limit}',

    telnyxnumbersPurchase: '/telnyxnumbers',
    numbersPurchase: '/number',
    telnyxnumbersDelete: '/number',
    numbersDelete: '/numbercarriergroup',
    numbersDeleteOnly: '/number',
    forwardingNumber: '/number',
    telnyxnumbersGroup: '/numbercarriergroup',
    numbercarriergroup: '/numbercarriergroup',
    numbercarriergroupWithNew: '/contactlist/new',
    telnyxnumbersGroupDelete: '/telnyxnumbersgroup/{telnyxID}',
    deleteMulSoundfile: '/soundfile',
    deleteMulCampaign: '/campaign',
    deleteMulContactlist: '/contactlist',
    // getCidList:"/contactlist?page={page}&limit={limit}&type={number}",
    // getDncList:"/contactlist?page={page}&limit={limit}&type=3",
    getCampaignList: "/campaign?page={page}&limit={limit}",
    getCampaignById: "/campaign/{campaignId}/detail",
    deleteCampaign: "/campaign/{campaignId}",
    campaigncompare: "/cdr/compare/campaign",
    campaigncomparehourly: "/cdr/stats/campaign/hourly?date={from}&compaignId={campaignId}",
    campaigncomparedaily: "/cdr/stats/campaign/daily?date={from}&compaignId={campaignId}",
    CRDList: "/cdr?page={page}&limit={limit}",
    CdrStatsDaily: "/cdr/stats/daily?page={page}&limit={limit}&from={from}&to={to}&amdstatus={amdstatus}&callstatus={callstatus}&campaignId={campaignId}&calltype={calltype}",
    CdrstatsHourlyAleg: "/cdr/stats/hourly/aleg?page={page}&limit={limit}&from={from}&to={to}&amdstatus={amdstatus}&callstatus={callstatus}&campaignId={campaignId}&calltype={calltype}",
    CdrstatsHourlyBleg: "/cdr/stats/hourly/bleg?page={page}&limit={limit}&from={from}&to={to}&amdstatus={amdstatus}&callstatus={callstatus}&campaignId={campaignId}&calltype={calltype}",
    SearchCRDList: "/cdr?page={page}&limit={limit}&from={from}&to={to}&amdstatus={amdstatus}&callstatus={callstatus}&campaignId={campaignId}&calltype={calltype}&callTo={callTo}",    
    getCampaignHistory:
      "/user/{userId}/campaign/{campaignId}/campaign-history?page={page}&limit={limit}",
    createCompaign: "/campaign",
    updateCompaign: "/campaign/{campaignId}",
    recycleCampaign: "/campaign/{campaignId}/recycle?Status={status}",
    updateCampaignV2: "/user/{userId}/campaign/{campaignId}",
    // deleteCampaign: "/user/{userId}/campaign/{campaignId}",
    testYourCall: "/user/{userId}/campaign/test",
    performCampaignAction:
      "/user/{userId}/campaign/{campaignId}?action={action}",
    systemMessage: "/systemmessage/{id}",
    getTotalNumbersUploadedContacts: "/user/{userId}/userDetail",
    exportReportData: "/cdr/export?from={from}&to={to}&amdstatus={amdstatus}&callstatus={callstatus}&campaignId={campaignId}&calltype={calltype}&multipleemails={multipleemails}",
    deleteDncNumber: "/user/{userId}/dnc",
    getSoundList: "/soundfile",
    AddNewSound: "/soundfile",
    getDefaultSound: "/user/{userId}/soundfile/default",
    contactList: "/user/{userId}/contactlist/{contactListId}",
    soundFile: "/user/{userId}/soundfile/{soundFileId}",
    updateUserAccount: "/user/{userId}",
    updatePassword: "/user/{userId}/updatePassword",
    resendVerify: "/resendverify?userId={userId}",
    verify:
      "/verify?token={token}&billingParentId={parentId}&password={password}",
    confirm: "/confirm?token={token}",
    sendSupportMessage: "/user/{userId}/sendsupportmessage",
    getCurrentFunds: "/user/{userId}/currentfunds",
    getBillingDetails: "/user/{userId}/billingdetails",
    getParentBillingInfo: "/user/{userId}/billingParent",
    deleteAccountLogo: "/user/{userId}/logo",
    checkoutWepayAccount: "/user/{userId}/wepay/checkout",
    updateCampaign: "/user/{userId}/campaign/{campaignId}/{method}",
    getCampaignDetail: "/user/{userId}/campaign/{campaignId}/detail",
    stripeKey: "/user/{userId}/strip/key",
    stripeCharge: "/user/{userId}/strip/charge",
    getUserById: "/user/{userId}",
    getRequestApiKeyByUserId: "/user/{userId}/apitoken",
    textToSpeach: "/user/{userId}/soundfile/tts",
    exportCampaign: "/user/{userId}/campaign/{campaignId}/export?action={action}",
    callCampaign: "/campaign/{campaignId}?action={action}",
    dublicateCampaign: "/campaign/{campaignId}/duplicate",
    callTestCampaign: "/campaign/{campaignId}/test",
    callTtsTestCampaign: "/campaign/{campaignId}/test/tts",
    platTtsAudio:"/campaign/tts/playaudio",
    rerunCampaign: "/user/{userId}/campaign/{campaignId}/rerun",
    addDNCFile: "/user/{userId}/adddncbulk",
    removeDNCNumber: "/user/{userId}/dnc?number={number}",
    findDNCNumber: "/user/{userId}/dnc?number={number}",
    userStats: '/user/stats',
    callReports: '/user/callreports?id={campaignId}&from={from}&to={to}',
    callDispositions: '/user/calldispositions',
    callCosts: '/user/callcosts',
    callCount: '/user/callcount',
    callLoc: '/user/callloc',
    alegChannels: '/user/Aleg',
    blegChannels: '/user/Bleg',
    campaignStat: '/campaignstat',
    updateSpeed: "/campaign/{campaignId}/speed",
    costReport: "/user/cost?"
  },

  pageUrl: {
    login: "/auth/login",
    signup: "/auth/register",
    forgotPassword: "/auth/forgot-password",
    home: "/rvmsas/dashboard"
  },

  apiRequestHeaders: {
    default: {
      contentType: "application/json",
      source: "Web",
      requestCode: "123",
      ifModifiedSince: "0",
      cacheControl: "no-cache",
      pragma: "no-cache"
    }
  },

  apiRequestHeaderKeys: {
    contentType: "Content-Type",
    authorization: "Authorization",
    xAuthorization: "X_AUTHORIZATION",
    source: "X_SOURCE",
    requestCode: "X_REQUEST_CODE",
    authToken: "x_auth_token",

    ifModifiedSince: "If-Modified-Since",
    cacheControl: "Cache-Control",
    pragma: "Pragma"
  },

  errorCodes: {
    authenticationFailure: "1001",
    accessDenied: "1002",
    requestNotValid: "1003",
    businessRuleFailure: "1004",
    exception: "1005",
    notFound: "1006"
  },

  dateFormats: {
    mmddyyyy: "MM/dd/yyyy",
    yyyyMMddHHmmss: "yyyy-MM-dd HH:mm:ss",
    MMDDYYYYhhmm: "MM/DD/YYYY hh:mm"
  },

  events: {
    navigation: "navigate",
    userLoaded: "userLoaded",
    deleteSoundFile: "deleteSoundFile",
    deleteContactList: "deleteContactList",
    loadSoundFiles: "loadSoundFiles",
    loadContactLists: "loadContactLists",
    deleteUploadedFile: "deleteUploadedFile",
    showLoader: "showLoader"
  },

  toasterConfig: {
    showCloseButton: true,
    tapToDismiss: true,
    timeout: 2000,
    positionClass: "toast-top-right",
    animation: "flyRight"
  },

  sessionTimeout: {
    timeoutComponentShowTime: 60, //seconds for how long to show component
    showComponentBeforeTime: 100, // milliseconds before timeout for when to show the component.
    tickTime: 2 // seconds
  },

  options: {
    title: {
      display: true,
      text: "Listening Duration",
      position: "right"
    },
    animation: {
      numSteps: 8,
      easing: "easeOutBounce"
    },
    toolTip: {
      bodyFontSize: 25
    },
    legend: {
      display: true,
      fullWidth: true,
      reverse: true,
      position: "bottom"
    }
  },
  campaignStatus: {
    1: "New",
    2: "Running",
    3: "Stopped",
    4: "Complete"
  },

  campaignTypes: {
    1: "Voice Only",
    2: "Live Answer And Answer Machine No Transfer",
    3: "Live Answer And Answer Machine With Transfer",
    4: "Live Answer Only No Transfer",
    5: "Live Answer Only With Transfer",
    6: "Text Blast"
  },

  campaignExportOptions: {
    "csv": 1,
    "excel": 2,
    "pdf": 3,
    "xml": 4
  }
  ,
  campaigns: {
    voiceOnly: {
      id: 1,
      sound: true,
      soundVoiceMail: false,
      soundDNC: false,
      soundTransfer: false,
      testYourMessage: false,
      testYourCall: true,
      contactListUpload: true,
      keywords: false,
      summary: true,
      message: false
    },
    laAndAmNoTransfer: {
      id: 2,
      sound: true,
      soundVoiceMail: true,
      soundDNC: true,
      soundTransfer: false,
      testYourMessage: false,
      testYourCall: true,
      contactListUpload: true,
      keywords: false,
      summary: true,
      message: false
    },
    laAndAmWithTransfer: {
      id: 3,
      sound: true,
      soundVoiceMail: true,
      soundDNC: true,
      soundTransfer: true,
      testYourMessage: false,
      testYourCall: true,
      contactListUpload: true,
      keywords: false,
      summary: true,
      message: false
    },
    liveAnswerOnlyNoTransfer: {
      id: 4,
      sound: true,
      soundVoiceMail: false,
      soundDNC: true,
      soundTransfer: false,
      testYourMessage: false,
      testYourCall: true,
      contactListUpload: true,
      keywords: false,
      summary: true,
      message: false
    },
    liveAnswerOnlyWithTransfer: {
      id: 5,
      sound: true,
      soundVoiceMail: false,
      soundDNC: true,
      soundTransfer: true,
      testYourMessage: false,
      testYourCall: true,
      contactListUpload: true,
      keywords: false,
      summary: true,
      message: false
    },
    textBlast: {
      id: 6,
      sound: false,
      soundVoiceMail: false,
      soundDNC: false,
      soundTransfer: false,
      testYourMessage: true,
      testYourCall: false,
      contactListUpload: true,
      keywords: true,
      summary: true,
      message: true
    }
  },

  soundFileType: {
    sound: "sound",
    dnc: "dnc",
    vm: "vm",
    transfer: "transfer"
  },

  dataKeys: {
    domainUser: "domainUser",
    assetsPath: "assetsPath",
    userInfo: "userInfo"
  },

  contractTypes: [
    {
      id: 1,
      name: "Pay As You Go"
    },
    {
      id: 2,
      name: "Monthly"
    },
    {
      id: 3,
      name: "Quarterly"
    },
    {
      id: 4,
      name: "Semi Annually"
    },
    {
      id: 5,
      name: "Annually"
    }
  ],

  billingTypes: [
    {
      id: 1,
      name: "Per Contact"
    },
    {
      id: 2,
      name: "6 Secs"
    },
    {
      id: 3,
      name: "30 Secs"
    },
    {
      id: 4,
      name: "Per Minute"
    }
  ],

  paymentMethods: {
    Wepay: 1,
    Stripe: 2
  }
};
