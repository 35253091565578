import {Injectable, Injector, RendererFactory2} from "@angular/core";
import {constants} from "./app.constants";
import { AppUtils } from "../app/core/_base/crud/utils/app.utils";

@Injectable()
export class AppGlobal {
  
  public static isFileUploadInProgress = false;
  private static data = {};

  /**
   * Allows for retrieving services using `AppModule.injector.get(MyService)`
   * This is good to prevent injecting the service as constructor parameter.
   **/
  static injector: Injector;
  
  public static hasData(key: string): boolean {
    return this.data.hasOwnProperty(key);
  }
  
  public static addData(key: string, value: any) {
    this.data[key] = value;
  }
  
  public static getData(key: string) {

    if (this.hasData(key)) {
      return this.data[key];
    }
    return null;
  }
  
  public static getDataAndRemove(key: string) {
    const value = this.getData(key);
    this.removeData(key);
    return value;
  }
  
  public static removeData(key: string) {
    if (this.hasData(key)) {
      delete this.data[key];
    }
  }

  public static getCampaignType(campaignType) {
    if (campaignType) {
      return constants.campaignTypes[campaignType];
    }
    return "";
  }

  public static getCampaignStatus(campaignStatus) {
    if (campaignStatus) {
      return constants.campaignStatus[campaignStatus];
    }
    return "";
  }

  public static setInjector(injector: Injector) {
    if(injector) {
      AppGlobal.injector = injector;
    }
  }

  public static getFullAssetPath(asset) {
    return AppGlobal.getData(constants.dataKeys.assetsPath) + asset;
  }

  
  public static getContractTypeById(contractTypeId): any {
    const contractTypes = constants.contractTypes;
    
    if(!AppUtils.isUndefinedOrNull(contractTypes)) {
      for(let contractType of contractTypes) {
        if(contractType.id === contractTypeId) {
          return contractType;
        }
      }
    }
    return null;
  }
  
  public static getBillingTypeById(billingTypeId): any {
    const billingTypes = constants.billingTypes;
    
    if(!AppUtils.isUndefinedOrNull(billingTypes)) {
      for(let billingType of billingTypes) {
        if(billingType.id === billingTypeId) {
          return billingType;
        }
      }
    }
    return null;
  }

  public static addJsToElement(uri: string): HTMLScriptElement {
    const rendererFacotry = AppGlobal.injector.get(RendererFactory2);
    const renderer = rendererFacotry.createRenderer(null, null);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = uri;
    renderer.appendChild(document.body, script);
    return script;
  }
}
