import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { constants } from "../../../../app.constants";
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppUtils } from "../../../../core/_base/crud/utils/app.utils";
import { constant } from 'lodash';

@Injectable()
export class ContactListService {
	constructor(private http: HttpClient) { }

	getContactList(options) {

		// let url = constants.apiUrl.getContactList.replace('{isDoNotContactList}', isDoNotContactList.toString());
		let url = constants.apiUrl.getContactList;
		if (!AppUtils.isUndefinedOrNull(options)) {
			url += `page=${options.currentPage}&limit=${options.pageSize}&type=1&listall=${options.listAll}`;
		}
		return this.http.get(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));
	}

	getCidList(options) {

		// let url = constants.apiUrl.getContactList.replace('{isDoNotContactList}', isDoNotContactList.toString());
		let url = constants.apiUrl.getContactList;
		if (!AppUtils.isUndefinedOrNull(options)) {
			url += `page=${options.currentPage}&limit=${options.pageSize}&type=2&listall=${options.listAll}`;
		}
		return this.http.get(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));
	}

	getdncList(options) {

		let url = constants.apiUrl.getContactList;
		if (!AppUtils.isUndefinedOrNull(options)) {
			url += `page=${options.currentPage}&limit=${options.pageSize}&type=3&listall=${options.listAll}`;
		}
		return this.http.get(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));
	}

 getDnc(DncId:string):Observable<any>{
  const url=constants.apiUrl.addContactList+"/"+DncId+"?type=3";
 
  return this.http.get(url);
  
  }

  getCallerGroup(callerGroupId:string):Observable<any>{
	  const url=constants.apiUrl.addContactList+"/"+callerGroupId+"?type=2";
	  return this.http.get(url);
  }


	searchDncList(options){
		let url = constants.apiUrl.getDncNumberList;
		if (!AppUtils.isUndefinedOrNull(options)) {
			url = constants.apiUrl.getDncNumberList
				.replace("{number}", options.dncNumber)
				.replace("{page}", options.currentPage)
				.replace("{limit}", options.pageSize);

		}
		return this.http.get(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));
	}

	deleteDncNumber(options){
		let url = constants.apiUrl.deleteDncNumberList
			.replace("{contactlistid}", options.contactId)
			.replace("{number}", options.dncNumber)
		return this.http.delete(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));
	}

	deleteContactList(contactListId: string) {
		let url = constants.apiUrl.addContactList+'/'+contactListId;
		return this.http.delete(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));
	}

	deleteCidList(contactListId: string) {
		let url = constants.apiUrl.addContactList+'/'+contactListId;
		return this.http.delete(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));
	}
	deleteDncList(contactListId: string) {
		let url = constants.apiUrl.addContactList+'/'+contactListId;
		return this.http.delete(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));
	}

	deleteMultipleContactList(data: any) {

		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: data,
		};

		let url = constants.apiUrl.deleteMulContactlist;
		return this.http.delete(url, options)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));
	}

	deleteMultipleCidList(data: any) {

		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: data,
		}
		let url = constants.apiUrl.deleteMulContactlist;
		return this.http.delete(url, options)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));
	}


	deleteMultipleDncList(data: any) {

		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: data,
		};

		let url = constants.apiUrl.deleteMulContactlist;
		return this.http.delete(url, options)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));
	}

	AddNewContactList(data: any){
		const url = constants.apiUrl.addContactList;
		return this.http.post(url, data, {observe: 'response'})
			.pipe(
				map(response => response),
				catchError((error: any) => {
					return observableThrowError(error);
				})
			);
	}

	AddNewCidList(data: any){
		const url = constants.apiUrl.addContactList;
		return this.http.post(url, data, {observe: 'response'})
			.pipe(
				map(response => response),
				catchError((error: any) => {
					return observableThrowError(error);
				})
			);
	}

	AddNewDncList(data: any){
		const url = constants.apiUrl.addContactList;
		return this.http.post(url, data, {observe: 'response'})
			.pipe(
				map(response => response),
				catchError((error: any) => {
					return observableThrowError(error);
				})
			);
	}

	addDNCNumber(dncId,number){
		const url=constants.apiUrl.addDNCnumber.replace('{dncId}',dncId);
		let body={"number":number}
		return this.http.post(url,body).pipe(
			map(response => response),
			catchError((error: any) => {
				return observableThrowError(error);
			})
		);

	}

	downloadContactList(id:string){
const url=constants.apiUrl.downloadContactList.replace('{contactlistid}',id);
return this.http.get(url)
			.pipe(
				map(response => response),
				catchError((error: any) => {
					return observableThrowError(error);
				})
			);
	}

	private _getContactListServiceUrl(userId: string, contactListId: string) {
		return constants.apiUrl.contactList.replace('{userId}', userId).replace('{contactListId}', contactListId);
	}
}
