import {Input} from "@angular/core";
import { Parent } from "./Parent";

export class Paginator extends Parent {
  @Input() showPaging = true;
  @Input() showInfo = true;
  @Input() pageSize = 10;
  @Input() lazyLoad = false;
  
  currentPage = 1;
  totalRecords = 0;
  
  constructor(pageSize?, showPaging?, currentPage?, lazyLoad?) {
    super();
    if(pageSize) {
      this.pageSize = pageSize;
    }
  
    if(showPaging) {
      this.showPaging = showPaging;
    }
    
    if(currentPage) {
      this.currentPage = currentPage;
    }

    if(lazyLoad) {
        this.lazyLoad = lazyLoad;
    }
  }
}