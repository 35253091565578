import {User} from "../../models/users.model";
import {AppGlobal} from "../../../../../app.global";
import {AppUtils} from "../../../crud/utils/app.utils";
import {Subscription} from "rxjs";
import { GoogleLoginService } from "../../index";
import {NgZone, Injector} from "@angular/core";
import { constants} from "../../../../../app.constants";
import { MyAuthService} from "../../../../auth";
import {Router} from "@angular/router";
import { Parent } from "./Parent";

export class GmailLogin extends Parent{
  
  private zone: NgZone;
  
  myAuthService: MyAuthService;
  router: Router;
  
  error = '';
  user: User;
  loading: Subscription;
  
  constructor(injector?: Injector) {
    super(injector);
  //  this.zone = AppGlobal.injector.get(NgZone);
    this.router = AppGlobal.injector.get(Router);
  
    this.myAuthService = AppGlobal.injector.get(MyAuthService);
    
  //  this.user = AppGlobal.getData(constants.dataKeys.domainUser);
  }
  
  clearError() {
    this.error = '';
  }
}