// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login, MyAuthService } from '../../../../core/auth';
import { User } from '../../../../core/_base/layout/models/users.model';
import { AppGlobal } from '../../../../app.global';
import { constants } from '../../../../app.constants';
@Component({
  selector: 'kt-confirm-sign-up',
  templateUrl: './confirm-sign-up.component.html',
  styleUrls: ['./confirm-sign-up.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmSignUpComponent implements OnInit {

  user: User;
  token: any;
  success: boolean = false;
  private unsubscribe: Subject<any>;
  private returnUrl: any;


  /**
 * Component constructor
 *
 * @param router: Router
 * @param auth: AuthService
 * @param authNoticeService: AuthNoticeService
 * @param translate: TranslateService
 * @param store: Store<AppState>
 * @param fb: FormBuilder
 * @param cdr
 * @param route
 */
  constructor(
    private router: Router,
    private auth: AuthService,
    private myAuthService: MyAuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.unsubscribe = new Subject();
    //	this.user = AppGlobal.getData(constants.dataKeys.domainUser);
  }


  ngOnInit() {
    this.token = this.route.snapshot.queryParams.token;
    if (!this.token) {
      this.router.navigateByUrl('/auth/login');
    }
    this.checkSignUpVerification();
  }

  checkSignUpVerification() {
    this.myAuthService.confirmUser(this.token).subscribe((response: any) => {
      if (response) {
        this.success = true;
        this.cdr.markForCheck();
        this.afterSuccessfulUser();
      }
    },
      (error) => {
        // this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');					AUTH.VALIDATION.INVALID_LOGIN
        this.authNoticeService.setNotice(error.submessage, 'danger');
        setTimeout(() => {
          this.router.navigateByUrl('/auth/login');
        }, 3000);
      });
  }

	afterSuccessfulUser(){
		setTimeout(() => {
			this.router.navigateByUrl('/rvmsas/dashboard');  
		  }, 5000);
	}
	  /*
    * On destroy
    */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.success = false;
	}
}
