// Angular
import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ChangeDetectorRef,
} from "@angular/core";
// RxJS
import { interval, Subscription } from "rxjs";
// Layout
import {
	SubheaderService,
	UserService,
} from "../../../../../core/_base/layout";
import { Breadcrumb } from "../../../../../core/_base/layout/services/subheader.service";
import { AppComponent } from "../../../../../app.component";
import { AuthService } from "../../../../../core/auth";
import { Router } from "@angular/router";
import _ from "lodash";
import { WidgetUtil } from "../../../../../core/_base/crud/utils/widget.util";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: "kt-subheader1",
	templateUrl: "./subheader1.component.html",
	styleUrls: ["./subheader1.component.scss"],
})
export class Subheader1Component implements OnInit, OnDestroy, AfterViewInit {
	loading: Subscription;
	route: string;
	regenerateDialouge: any;
	ngbModalRef: NgbModalRef;

	// Public properties
	@Input() fluid: boolean;
	@Input() clear: boolean;
	subscription: Subscription;
	today: number = Date.now();
	title: string = "";
	desc: string = "";
	breadcrumbs: Breadcrumb[] = [];
	//balanceInfo: any;
	balanceInfo = interval(20000);
	// Private properties
	private subscriptions: Subscription[] = [];
	fundsAvailable: any;
	/**
	 * Component constructor
	 *
	 * @param subheaderService: SubheaderService
	 */
	constructor(
		public subheaderService: SubheaderService,
		private userService: UserService,
		private cd: ChangeDetectorRef,
		private ngbModal: NgbModal
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.currentfunds();
	}

	/**
	 * After view init
	 */
	ngAfterViewInit(): void {
		this.subscriptions.push(
			this.subheaderService.title$.subscribe((bt) => {
				// breadcrumbs title sometimes can be undefined
				if (bt) {
					Promise.resolve(null).then(() => {
						this.title = bt.title;
						this.desc = bt.desc;
					});
				}
			})
		);

		this.subscriptions.push(
			this.subheaderService.breadcrumbs$.subscribe((bc) => {
				Promise.resolve(null).then(() => {
					this.breadcrumbs = bc;
				});
			})
		);

		this.subscription = this.balanceInfo.subscribe((val) =>
			this.currentfunds()
		);
	}

	currentfunds() {
		this.userService.getBalanceInfo().subscribe(
			(response: any) => {
				this.balanceInfo = response.data;
				this.fundsAvailable = response.data.fundsAvailable;
				this.cd.markForCheck();
			},
			(error) => {
				AppComponent.openSnackBar(error.submessage);
			}
		);
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach((sb) => sb.unsubscribe());
	}
}
