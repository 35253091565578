import {Injectable, NgZone} from "@angular/core";
import {environment} from "../../../../../environments/environment";
import {AppUtils} from "../../crud/utils/app.utils";

@Injectable()
export class GoogleLoginService {
  
  constructor(private zone: NgZone) {
  }
  
  public login(callback) {
    
    window['gapi'].load('auth2', function () {
      const auth2: any = window['gapi'].auth2.init({
        client_id: environment.googleClientId,
        //cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
  
      //Login button reference
      const loginButton: any = document.getElementById('google-login-button');
      
      if(AppUtils.isUndefinedOrNull(loginButton)) {
        return;
      }
      
      auth2.attachClickHandler(loginButton, {},
        (userProfile) => {
          //Getting profile object
          const profile = userProfile.getBasicProfile();
          
          const result = {
            firstName: profile.getGivenName(),
            lastName: profile.getFamilyName(),
            email: profile.getEmail(),
            id: profile.getId()
            // profilePicture: profile.getImageUrl(),
            //token: userProfile.getAuthResponse().id_token,
          };
          
          console.log(userProfile);
          
          callback(result);
          
        }, function (error) {
          this.error = (JSON.stringify(error, undefined, 2));
        });
    });
  }
  
  public logout(callback) {
    //You will be redirected to this URL after logging out from Google.
    //const homeUrl = environment.loginPage;
    //const logoutUrl = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" + homeUrl;
    //document.location.href = logoutUrl;
  
    if(window['gapi'] && window['gapi'].auth2) {
      const authInstance = window['gapi'].auth2.getAuthInstance();
      authInstance.signOut().then(() => {
        this.zone.run(() => {
          authInstance.disconnect();
          authInstance.currentUser.get().disconnect();
          authInstance.currentUser.get().reloadAuthResponse();
          callback();
        });
      });
    } else {
      callback();
    }
  }
}