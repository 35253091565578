// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login, MyAuthService } from '../../../../core/auth';
import { User } from '../../../../core/_base/layout/models/users.model';
import { AppGlobal } from '../../../../app.global';
import { constants } from '../../../../app.constants';
import { CommonService } from '../../../../core/_base/layout';

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	EMAIL: '',
	PASSWORD: ''
};

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	assetsHome: any;
	logoPath: any;

	showPassword = false;
	resendTokenDialog: any;
	user: User;

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private myAuthService: MyAuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private commonService: CommonService,
	) {
		this.unsubscribe = new Subject();

		if(window.location.hostname == 'localhost'){
			this.setLogoPath("portal.rvm.sas2.co");
		}
		else{
			this.setLogoPath(window.location.hostname);
		}
		//	this.user = AppGlobal.getData(constants.dataKeys.domainUser);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.initLoginForm();
		this.getAssetHome();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '/';
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		// demo message to show
		// if (!this.authNoticeService.onNoticeChanged$.getValue()) {
		// 	const initialNotice = `Use account
		// 	<strong>${DEMO_PARAMS.EMAIL}</strong> and password
		// 	<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
		// 	this.authNoticeService.setNotice(initialNotice, 'info');
		// }

		this.loginForm = this.fb.group({
			email: [DEMO_PARAMS.EMAIL, Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: [DEMO_PARAMS.PASSWORD, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		// this.user = AppGlobal.getData(constants.dataKeys.domainUser);
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			username: controls['email'].value,
			password: controls['password'].value
		};
		this.myAuthService.login(authData)
			.subscribe((user: any) => {
				if (user.body.data.isVerified) {
					this.store.dispatch(new Login({ authToken: user.accessToken }));
					this.myAuthService.addLoginUserInLocalStorage(user);
					this.loading = false;
					this.cdr.markForCheck();
					this.router.navigateByUrl(this.returnUrl); // Main page
				} else {
					this.authNoticeService.setNotice(this.translate.instant(user.submessage), 'danger');
				}
			}, (error: any) => {
				this.loading = false;
				this.cdr.markForCheck();
				this.authNoticeService.setNotice(this.translate.instant(error.submessage), 'danger');
			});

	}

	getAssetHome() {
		this.myAuthService.getAssetPath()
			.subscribe((res: any) => {
				if (res.data) {
					localStorage.setItem('assethome', res.data.name);
				}
			}, (error: any) => {
				//	this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
			});
	}
	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	setLogoPath(hostName){
		this.myAuthService.getAssetPath().subscribe((res: any) => {
			if(res){
				this.assetsHome = res.data.name;
				localStorage.setItem('assetsPath', res.data.name);
			}
		},(error:any) => {
			console.log(error)
		});
		this.myAuthService.getUserByDomain(hostName).subscribe((response: any) => {
			if (response && response.customize.logoPath != '') {
				this.cdr.detectChanges();
				this.logoPath = this.assetsHome+response.customize.logoPath;
				localStorage.setItem('logoPath', this.logoPath);
				//this.layoutConfigService.setConfig({self: {logo: this.logoPath}}, true);
				this.cdr.detectChanges();
			}else {
				//AppComponent.openSnackBar("Failed to load logo path from domain");
			//	this.logoPath = "./assets/media/logos/logo.png";
			}
		}, (error: any) => {
		//	this.logoPath = "./assets/media/logos/logo.png";
			//AppComponent.openSnackBar("Enable to load domain:"+ error);
		});
	}
}
