import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constants } from "../../../../app.constants";
import { throwError as observableThrowError, Observable, of } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getUserCallStats() {
    const url = constants.apiUrl.userStats;
      return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  getUserCallReports(id,from,to) {
    const url = constants.apiUrl.callReports
    .replace("{campaignId}", id)
    .replace("{from}", from)
    .replace("{to}",to);
      return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }
  
  getUserCallDispositions() {
    const url = constants.apiUrl.callDispositions;
      return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }
  
  getUsercallcosts() {
    const url = constants.apiUrl.callCosts;
      return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }
  getUserCallCountStats() {
    const url = constants.apiUrl.callCount;
      return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }
  getUsercalllocAsrAloc() {
    const url = constants.apiUrl.callLoc;
      return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }
  getUserAlegChannels() {
    const url = constants.apiUrl.alegChannels;
      return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  getUserBlegChannels() {
    const url = constants.apiUrl.blegChannels;
      return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }
}
