import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'truncate'
})

export class TruncatePipe implements PipeTransform {

    transform(value: string, limit: number, trailString?: number): string {
        if (!value) {
            return value;
        }
        const trail = trailString ? trailString : '...';
        return (value.toString().length > limit) ? value.substring(0, limit) + trail : value;
    }
}
