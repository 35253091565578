import {AppGlobal} from "../../../../../app.global";
import { EmitterUtil } from "../../../crud/utils/emitter.util";
import { constants } from "../../../../../app.constants";
import { Injector, OnDestroy } from "@angular/core";

export class Parent implements OnDestroy {
  
  public primaryColor:string;
  secondaryColor: string;
  imageSrc: string;

  isViewLoaded = false;
  showFileUploader = true;

  constructor(injector?: Injector) {

    if(injector) {
      AppGlobal.setInjector(injector);
    }


    // if (AppGlobal.getData(constants.dataKeys.domainUser)) {
    //   this.updateColors();
    // }

    // EmitterUtil.get(constants.events.userLoaded).subscribe(() => {
    //     this.updateColors();
    // });
  }

  public updateColors(): void{
    const domainUser = AppGlobal.getData(constants.dataKeys.domainUser);
    this.primaryColor = domainUser.customize.primaryColor;
    this.secondaryColor = domainUser.customize.secondaryColor;
    this.imageSrc = AppGlobal.getData(constants.dataKeys.assetsPath) + domainUser.customize.logoPath;
  }

  public applyPrimaryBackground(){
    return {
      background: this.primaryColor,
      border: this.primaryColor
    };
  }
  
  public applyPrimaryColor(){
    return {
      color: this.primaryColor,
      border: this.primaryColor
    };
  }
  
  public applySecondaryColor(){
    return {
      color: this.secondaryColor,
      border: this.secondaryColor
    };
  }

  public applySecondaryBackground(){
    return {
      background: this.secondaryColor,
      border: this.secondaryColor
    };
  }

  reloadFileUploader() {
    this.showFileUploader = false;

    setTimeout(() => {
      this.showFileUploader = true;
    }, 10);
  }

  ngOnDestroy() {
    EmitterUtil.remove(constants.events.userLoaded);
  }

}