import { Paginator } from "./Paginator";
import {AppGlobal} from "../../../../../app.global";
import { Input } from "@angular/core";
import { constants } from "../../../../../app.constants";

export class SoundFileHelper extends Paginator {

  @Input() audioPlayer: any;
  isAudioPlayerEventRegistered = false;
  
  selectedSoundFile: any;
  
  constructor() {
    super();
  }
  
  onPlay(item) {
    this.setSelectedFile(item);
    
    setTimeout(() => {
      this.audioPlayer.play();
    }, 100);
  }

  setSelectedFile(item) {
    this.selectedSoundFile = item;
    this.selectedSoundFile.filePath = AppGlobal.getData(constants.dataKeys.assetsPath) + item.wavFilePath;

    if(this.selectedSoundFile.id !== item.id){
      this.audioPlayer.src = this.selectedSoundFile.filePath;
    }
  }

  onPause(item) {
    this.audioPlayer.pause();
  }

  clearSelectedSoundFile() {
    this.selectedSoundFile = null;
  }

  registerAudioPlayerEvents() {
    this.audioPlayer.onplay = () => {
      console.log("Play Event Triggered");
      this.selectedSoundFile.isPlaying = true;
    };

    this.audioPlayer.onpause = () => {
      console.log("Pause Event Triggered");
      this.selectedSoundFile.isPlaying = false;
    };

    this.isAudioPlayerEventRegistered = true;
  }
}