import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WebsocketServiceService {

  // private url = 'http://localhost:3000';
  private socket;

  constructor() { 
  //  this.socket = io(environment.apiBaseUrl);
  }

  public getCompaigns = () => {
    return Observable.create((observer) => {
        this.socket.on('GetCompaigns', (compaigns) => {
            observer.next(compaigns);
        });
    });
}

}
