import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";

import { throwError as observableThrowError, Observable } from "rxjs";

import { tap, catchError } from "rxjs/operators";

import { constants } from "../../../../app/app.constants";
import { AppUtils, EmitterUtil } from "../../_base/crud/index";
import { MyAuthService } from "../../auth/index";
import { environment } from "../../../../environments/environment";

@Injectable()
export class InterceptedHttp implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.cloneRequest(request);

    return next.handle(request).pipe(
      catchError(this.onCatch),
      tap(
        (res: any) => {
          if (res.type !== 0) {
            this.onSuccess(res);
          }
        },
        (error: any) => {
          this.onError(error);
        }
      )
    );
  }

  private cloneRequest(request: HttpRequest<any>): HttpRequest<any> {
    let headerKeys = constants.apiRequestHeaderKeys;
    let defaultHeaderOptions = constants.apiRequestHeaders.default;

    request = request.clone({
      headers: request.headers.set(
        headerKeys.contentType,
        defaultHeaderOptions.contentType
      )
    });
    request = request.clone({
      headers: request.headers.set(
        headerKeys.source,
        defaultHeaderOptions.source
      )
    });
    request = request.clone({
      headers: request.headers.set(
        headerKeys.requestCode,
        defaultHeaderOptions.requestCode
      )
    });
    request = request.clone({
      headers: request.headers.set(
        headerKeys.ifModifiedSince,
        defaultHeaderOptions.ifModifiedSince
      )
    });
    request = request.clone({
      headers: request.headers.set(
        headerKeys.cacheControl,
        defaultHeaderOptions.cacheControl
      )
    });
    request = request.clone({
      headers: request.headers.set(
        headerKeys.pragma,
        defaultHeaderOptions.pragma
      )
    });
    if (MyAuthService.isAuthenticated()) {
      let token = MyAuthService.getToken();
      request = request.clone({
        headers: request.headers.set(
          headerKeys.authorization,
          "Bearer " + token
        )
      });
      request = request.clone({
        headers: request.headers.set(
          headerKeys.xAuthorization,
          "Bearer " + token
        )
      });
      //request = request.clone({ headers: request.headers.set(headerKeys.authToken, 'Bearer ' + token) });
    }

    request = request.clone({ url: this.updateUrl(request.url) });
    return request;
  }

  private updateUrl(requestUrl: string) {
    // Append trailing slash
    /* if (requestUrl.indexOf('?') > 0) {
      requestUrl = requestUrl.slice(0, requestUrl.indexOf('?')) + '/' + requestUrl.slice(requestUrl.indexOf('?'));
    } else {
      requestUrl += '/';
    } */
    return environment.apiBaseUrl + requestUrl;
  }

  private onCatch(error: any, caught: Observable<any>): Observable<any> {
    return observableThrowError(error.error);
  }

  private onSuccess(response: Response): void {
    let token =
      response.headers.get("x_auth_token") !== null
        ? response.headers.get("x_auth_token")
        : response.headers.get("X_AUTH_TOKEN");
    if (!AppUtils.isUndefinedOrNull(token)) {
      MyAuthService.updateToken(token);
    }
  }

  private onError(error: any): void {
    if (error.status === 400 || error.status === 0) {
      let body = error;
      if (body && body.statusCode) {
        EmitterUtil.get(constants.events.navigation).emit([
          "/error",
          body.statusCode
        ]);
      } else {
        EmitterUtil.get(constants.events.navigation).emit(["/error", "400"]);
      }
    }
  }
}
