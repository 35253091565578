export enum Status {
    PROCESSING = 2,
    READY = 3,
    ERROR = 4
}

export enum FileStatus {
    PROCESSING = 'Processing',
    READY = 'Ready',
    ERROR = 'Error'
}