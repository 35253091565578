import {Component, OnDestroy, OnInit,Inject} from "@angular/core";
import {SimpleTimer} from "ng2-simple-timer";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {MyAuthService} from "../../../../../core/auth";
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material";
import { Router } from "@angular/router";
@Component({
  selector: 'cml-session-timeout-popup',
  templateUrl: './session-timeout-popup.component.html',
  styles: []
})
export class SessionTimeoutPopupComponent implements OnInit, OnDestroy {

  static isShowing = false;
  //seconds: number = constants.sessionTimeout.timeoutComponentShowTime;
  modalRef: NgbModalRef;
  private subscriptionId: string;

  constructor(private dialog: MatDialog,
    public dialogRef: MatDialogRef<SessionTimeoutPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
    private timerService: SimpleTimer, 
    private authService: MyAuthService,
    private router: Router,) { 

    }
  
  expireSession() {
    //this.modalRef.close('expired');
    this.dialogRef.close('expired')
    setTimeout(() => {
      this.router.navigateByUrl('/auth/login');
    }, 1000);
    MyAuthService._logout();
  }

  refreshSession() {
    this.timerService.unsubscribe(this.subscriptionId);
    this.authService.updateToken()
      .subscribe((result: any) => {
      });
    this.modalRef.dismiss('refreshed');
  }

  ngOnInit() {
    SessionTimeoutPopupComponent.isShowing = true;
  }

  ngOnDestroy() {
    SessionTimeoutPopupComponent.isShowing = false;
  }

}
