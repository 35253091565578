import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { constants } from "../../../../app.constants";
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppUtils } from "../../../../core/_base/crud/utils/app.utils";

@Injectable()
export class PhoneManagerService {
	constructor(private http: HttpClient) { }

	searchTelnyxNumbers(searchFilter) {

		// let url = constants.apiUrl.getContactList.replace('{isDoNotContactList}', isDoNotContactList.toString());
		let url = constants.apiUrl.telnyxSearch.replace('{country_code}', searchFilter.country_code).replace('{locality}', searchFilter.locality)
			.replace('{administrative_area}', searchFilter.administrative_area).replace('{limit}',
				searchFilter.limit).replace('{npa}', searchFilter.npa);


		return this.http.get(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}
	searchBulkNumbers(searchFilter) {

		// let url = constants.apiUrl.getContactList.replace('{isDoNotContactList}', isDoNotContactList.toString());
		let url = constants.apiUrl.bulkNumberSearch.replace('{npa}', searchFilter.npa);


		return this.http.get(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}

	getTelnyxNumbers(
		page: number,
		limit: number) {

		// let url = constants.apiUrl.getContactList.replace('{isDoNotContactList}', isDoNotContactList.toString());
		let url = constants.apiUrl.numbers.replace('{page}', '' + page)
			.replace('{limit}', '' + limit);
		return this.http.get(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}

	getTelnyxNumberGroupContactList(
		id: any,
		page: number,
		limit: number) {

		// let url = constants.apiUrl.getContactList.replace('{isDoNotContactList}', isDoNotContactList.toString());
		let url = constants.apiUrl.numbercarriergroupGroupContactGet.replace('{page}', '' + page)
			.replace('{limit}', '' + limit)
			.replace('{id}', '' + id);
		return this.http.get(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}

	getTelnyxNumberGroup(
		id: any,
		page: number,
		limit: number) {

		// let url = constants.apiUrl.getContactList.replace('{isDoNotContactList}', isDoNotContactList.toString());
		let url = constants.apiUrl.numbersGroupGet.replace('{page}', '' + page)
			.replace('{limit}', '' + limit)
			.replace('{id}', '' + id);
		return this.http.get(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}

	deleteNumber(phoneNumber) {
		let url = constants.apiUrl.telnyxnumbersDelete + '/' + phoneNumber;
		return this.http.delete(url)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}
	deleteNumberBulk(numberID) {
		let url = constants.apiUrl.numbersDeleteOnly;
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: numberID,
		};
		return this.http.delete(url, options)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}
	deleteGroup(phoneNumberID, contactListID) {
		let url = constants.apiUrl.telnyxnumbersGroup + '/' + phoneNumberID;
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: { contactListID},
		};
		return this.http.delete(url, options)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}
	deleteNumberGroupBulk(numberID, contactListID) {
		let url = constants.apiUrl.telnyxnumbersGroup;
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: {numberID, contactListID},
		};
		return this.http.delete(url, options)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}

	forwardNumber(phoneNumber, forwardingNumber) {
		let url = constants.apiUrl.forwardingNumber + '/' + phoneNumber;
		return this.http.patch(url, forwardingNumber)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}
	forwardNumbers(phoneNumbers, forwardingNumber) {
		let url = constants.apiUrl.forwardingNumber;
		return this.http.patch(url, { numberID: phoneNumbers, forwardingNumber })
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}
	groupNumber(phoneNumber, contactListID) {
		let url = constants.apiUrl.numbercarriergroup + '/' + phoneNumber;
		return this.http.post(url, { contactListID })
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}
	groupNumberMultiple(phoneNumbers, contactListID) {
		let url = constants.apiUrl.numbercarriergroup;
		const obj = { numberID: phoneNumbers, contactListID }
		return this.http.post(url, obj)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}
	groupNumberMultipleWithNewCL(numbers, name) {
		let url = constants.apiUrl.numbercarriergroupWithNew;
		const obj = { numbers, name }
		return this.http.post(url, obj)
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}


	purchaseTelnyxNumbers(numbers) {

		// let url = constants.apiUrl.getContactList.replace('{isDoNotContactList}', isDoNotContactList.toString());
		let url = constants.apiUrl.telnyxnumbersPurchase;
		return this.http.post(url, { phoneNumbers: numbers })
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}

	purchaseNumbers(numbers) {

		// let url = constants.apiUrl.getContactList.replace('{isDoNotContactList}', isDoNotContactList.toString());
		let url = constants.apiUrl.numbersPurchase;
		return this.http.post(url, { phoneNumbers: numbers })
			.pipe(map((response) => response),
				catchError((error: any) => {
					return observableThrowError(error);
				}));

	}
}
