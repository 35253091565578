import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constants } from "../../../../app.constants";
import { throwError as observableThrowError, Observable, of } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
// Model
import { Campaign } from "../index";

@Injectable()
export class CampaignService {
  detailCampaign: Campaign;

  constructor(private http: HttpClient) { }

  getCampaignList(page: number, limit: number) {
    const url = constants.apiUrl.getCampaignList
      .replace("{page}", "" + page)
      .replace("{limit}", "" + limit);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  getCampaignDetail(userId: string, campaignId: string) {
    const url = constants.apiUrl.getCampaignDetail
      .replace("{userId}", userId)
      .replace("{campaignId}", campaignId);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }


  performCampaignAction(userId: string, campaignId: string, action: string) {
    const url = constants.apiUrl.performCampaignAction
      .replace("{userId}", userId)
      .replace("{campaignId}", campaignId)
      .replace("{action}", action);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  getCampaignHistory(
    userId: string,
    campaignId: string,
    page: number,
    limit: number
  ) {
    const url = constants.apiUrl.getCampaignHistory
      .replace("{userId}", userId)
      .replace("{campaignId}", campaignId)
      .replace("{page}", "" + page)
      .replace("{limit}", "" + limit);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  callCampaignAction(id: string, action: string) {
    const url = constants.apiUrl.callCampaign.replace("{campaignId}", id).replace("{action}", action);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  getCampaignById(id: string) {
    const url = constants.apiUrl.getCampaignById.replace("{campaignId}", id);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }


  callTestCampaign(id: string, number: any) {
    const url = constants.apiUrl.callTestCampaign.replace("{campaignId}", id);
    return this.http.post(url, { number: number }).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }
  callTtsTestCampaign(id: string, number: any) {
    const url = constants.apiUrl.callTtsTestCampaign.replace("{campaignId}", id);
    return this.http.post(url, { number: number }).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }
  dublicateCampaign(id: string, campaignName: any, contactId, isTTS = false) {
    const url = constants.apiUrl.dublicateCampaign.replace("{campaignId}", id);
    let requestBody: any = { name: campaignName, contactlistId: contactId };
    if (isTTS) {
      requestBody = { name: campaignName, ttslistId: contactId };
    }
    return this.http.post(url, requestBody).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  exportCampaign(
    userId: string,
    campaignId: string,
    type: number
  ) {
    const url = constants.apiUrl.exportCampaign
      .replace("{userId}", userId)
      .replace("{campaignId}", campaignId)
      .replace("{action}", "" + type);

    return this.http.get(url).pipe(
      switchMap((response: any) => { return of(response.data.exportFile) }),

      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  rerunCampaign(
    userId: string,
    campaignId: string
  ) {
    const url = constants.apiUrl.rerunCampaign
      .replace("{userId}", userId)
      .replace("{campaignId}", campaignId);

    return this.http.post(url, {}).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  setDetailCampaign(campaign: any) {
    this.detailCampaign = campaign;
  }

  getDetailCampaign(): any {
    return this.detailCampaign;
  }

  updateCampaign(
    userId: string,
    campaignId: string,
    fieldName: string,
    body: any
  ) {
    const url = constants.apiUrl.updateCampaign
      .replace("{userId}", userId)
      .replace("{campaignId}", campaignId)
      .replace("{method}", fieldName);
    return this.http.post(url, body);
  }

  createCampaign(payload: any) {
    const url = constants.apiUrl.createCompaign;
    return this.http.post(url, payload).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  getSystemMessage(id: any) {
    const url = constants.apiUrl.systemMessage
      .replace("{id}", id);
    return this.http.get(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  UpdateCampaign(payload: any, campaignId) {
    const url = constants.apiUrl.updateCompaign.replace("{campaignId}", campaignId);
    return this.http.put(url, payload).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  updateSpeed(payload: any, campaignId) {
    const url = constants.apiUrl.updateSpeed.replace("{campaignId}", campaignId);
    return this.http.put(url, payload).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  updateCampaignV2(userId: string, compaignId: string, payload: any) {
    const url = constants.apiUrl.updateCampaignV2
      .replace("{userId}", userId)
      .replace("{campaignId}", compaignId);
    return this.http.put(url, payload).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  deleteCampaign(compaignId: string) {
    const url = constants.apiUrl.deleteCampaign
      .replace("{campaignId}", compaignId);
    return this.http.delete(url).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  sendTestCall(userId: string, payload) {
    const url = constants.apiUrl.testYourCall
      .replace("{userId}", userId)
    return this.http.post(url, payload).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  // Check Recycle process of new and existing campaign  
  recycleCampaign(campaignId: string, phoneBookName, status: string) {
    let payload = {
      "name": phoneBookName
    }
    const url = constants.apiUrl.recycleCampaign
      .replace("{campaignId}", campaignId)
      .replace("{status}", status)
    return this.http.post(url, payload).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  // Get Live Stats for every 10 second 
  getCampaignStat(payload) {
    const url = constants.apiUrl.campaignStat;
    return this.http.post(url, payload).pipe(
      map(response => response),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }


  getUserCallReportCost(from, to): Observable<any> {
    const url = constants.apiUrl.costReport + "from=" + from + "&to=" + to;
    return this.http.get(url);
  }

}
