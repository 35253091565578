import { AppGlobal } from "./../../../../app.global";
import { AppUtils } from "./app.utils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SessionTimeoutPopupComponent } from "../../../../views/partials/content/general/session-timeout-popup/session-timeout-popup.component";
import { MatDialog } from "@angular/material";
// import { UploadCsvFileComponent } from "../components/dialogs/upload-csv-file/upload-csv-file.component";
// import { UploadSoundFileComponent } from "../components/dialogs/upload-sound-file/upload-sound-file.component";
// import { ConfirmationComponent } from "../components/dialogs/confirmation/confirmation.component";
// import { UpdateNameComponent } from "../components/dialogs/update-name-component/update-name.component";
// import { AddFundComponent } from "../components/dialogs/add-fund/add-fund.component";
// import { UpdatePasswordComponent } from "../components/dialogs/update-password-component/update-password.component";
// import { RemoveNumberComponent } from "../components/dialogs/remove-number-component/remove-number.component";
// import { DncDownloadComponent } from "../components/dialogs/dnc-delete/dnc-download.component";
// import { UploadDNCFileComponent } from 'src/app/dnc/upload-dnc-file.component';

export class WidgetUtil {
	private static options = { backdrop: true, keyboard: false };

	// public static showConfirmationDialog(
	// 	ngbModal: NgbModal,
	// 	message: string,
	// 	yesLabel?: string,
	// 	noLabel?: string,
	// 	title?: string,
	// 	isStacked?: boolean
	// ) {
	// 	const ngbModalRef = ngbModal.open(
	// 		ConfirmationComponent,
	// 		WidgetUtil.options
	// 	);
	// 	const componentInstance = ngbModalRef.componentInstance;
	// 	componentInstance.message = message;

	// 	if (title) {
	// 		componentInstance.title = title;
	// 	}
	// 	if (yesLabel) {
	// 		componentInstance.yesLabel = yesLabel;
	// 	}

	// 	if (noLabel) {
	// 		componentInstance.noLabel = noLabel;
	// 	}

	// 	if (isStacked) {
	// 		componentInstance.isStacked = isStacked;
	// 	}

	// 	componentInstance.ngbModalRef = ngbModalRef;

	// 	return componentInstance;
	// }

	public static showSessionTimeoutDialog(ngbModal: NgbModal) {
		const ngbModalRef = ngbModal.open(
			SessionTimeoutPopupComponent,
			WidgetUtil.options
		);
		const componentInstance = ngbModalRef.componentInstance;

		componentInstance.modalRef = ngbModalRef;
		return componentInstance;
	}

	public static showSessionTimeoutPopup(dialog: MatDialog): any {
		const dialogRef = dialog.open(SessionTimeoutPopupComponent, {
			width: "650px",
			autoFocus: true,
			closeOnNavigation: true,
			disableClose: true,
			hasBackdrop: true,
		});
	}
	// public static showUploadContactListDialog(ngbModal: NgbModal, fileEvent, phoneNumberColumn, isHeadPresent) {

	//   const options = {
	//     "backdrop": true,
	//     "keyboard": false,
	//     "container": '.file-uploader',
	//     beforeDismiss: () => {
	//       return !AppGlobal.isFileUploadInProgress;
	//     }
	//   };

	//   const ngbModalRef = ngbModal.open(UploadCsvFileComponent, options);
	//   const componentInstance = ngbModalRef.componentInstance;
	//   componentInstance.fileEvent = fileEvent;

	//   if(!AppUtils.isUndefinedOrNull(phoneNumberColumn)) {
	//     componentInstance.phoneNumberColumn = phoneNumberColumn;
	//   }

	//   if(!AppUtils.isUndefined(isHeadPresent)) {
	//     componentInstance.isHeadPresent = isHeadPresent;
	//   }

	//   componentInstance.ngbModalRef = ngbModalRef;
	//   return componentInstance;
	// }

	// public static showUploadDNCListDialog(ngbModal: NgbModal, fileEvent, phoneNumberColumn, isHeadPresent) {

	//   const options = {
	//     "backdrop": true,
	//     "keyboard": false,
	//     "container": '.file-uploader',
	//     beforeDismiss: () => {
	//       return !AppGlobal.isFileUploadInProgress;
	//     }
	//   };

	//   const ngbModalRef = ngbModal.open(UploadDNCFileComponent, options);
	//   const componentInstance = ngbModalRef.componentInstance;
	//   componentInstance.fileEvent = fileEvent;

	//   if(!AppUtils.isUndefinedOrNull(phoneNumberColumn)) {
	//     componentInstance.phoneNumberColumn = phoneNumberColumn;
	//   }

	//   if(!AppUtils.isUndefined(isHeadPresent)) {
	//     componentInstance.isHeadPresent = isHeadPresent;
	//   }

	//   componentInstance.ngbModalRef = ngbModalRef;

	//   return componentInstance;
	// }

	// public static showUploadSoundFileDialog(ngbModal: NgbModal, fileEvent) {

	//   const options = {
	//     "backdrop": true,
	//     "keyboard": false,
	//     "container": '.file-uploader',
	//     beforeDismiss: () => {
	//       return !AppGlobal.isFileUploadInProgress;
	//     }
	//   };

	//   const ngbModalRef = ngbModal.open(UploadSoundFileComponent, options);
	//   const componentInstance = ngbModalRef.componentInstance;
	//   componentInstance.fileEvent = fileEvent;

	//   componentInstance.ngbModalRef = ngbModalRef;
	//   return componentInstance;
	// }

	// public static showUpdateNameDialog(ngbModal: NgbModal, data: any, title: string) {

	//   const ngbModalRef = ngbModal.open(UpdateNameComponent, WidgetUtil.options);
	//   const componentInstance = ngbModalRef.componentInstance;

	//   componentInstance.data = data;
	//   componentInstance.title = title;
	//   componentInstance.ngbModalRef = ngbModalRef;
	//   return componentInstance;
	// }

	// public static showAddFundsDialog(ngbModal: NgbModal, callback: any) {

	//   const options = {
	//     "backdrop": true,
	//     "keyboard": false,
	//     beforeDismiss: () => {
	//       if(callback) {
	//         callback();
	//       }

	//       return true;
	//     }
	//   };
	//   const ngbModalRef = ngbModal.open(AddFundComponent, options);

	//   const componentInstance = ngbModalRef.componentInstance;

	//   componentInstance.ngbModalRef = ngbModalRef;

	//   return componentInstance;
	// }

	// public static showUpdatePasswordDialog(ngbModal: NgbModal) {

	//   const ngbModalRef = ngbModal.open(UpdatePasswordComponent, WidgetUtil.options);
	//   const componentInstance = ngbModalRef.componentInstance;
	//   componentInstance.ngbModalRef = ngbModalRef;
	//   return componentInstance;
	// }

	// public static deleteDncNumberDialog(ngbModal: NgbModal) {

	//   const ngbModalRef = ngbModal.open(RemoveNumberComponent, WidgetUtil.options);
	//   const componentInstance = ngbModalRef.componentInstance;
	//   componentInstance.ngbModalRef = ngbModalRef;
	//   return componentInstance;
	// }

	// public static showDncDownloadDialog(ngbModal: NgbModal, dncId) {

	//   const ngbModalRef = ngbModal.open(DncDownloadComponent, WidgetUtil.options);
	//   const componentInstance = ngbModalRef.componentInstance;
	//   componentInstance.dncListId = dncId;

	//   componentInstance.modalRef = ngbModalRef;
	//   return componentInstance;
	// }
}
