import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User, MyAuthService } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from '../register/confirm-password.validator';
@Component({
  selector: 'kt-confirm-reset-pass',
  templateUrl: './confirm-reset-pass.component.html',
  styleUrls: ['./confirm-reset-pass.component.scss']
})
export class ConfirmResetPassComponent implements OnInit {

  resetForm: FormGroup;
	loading = false;
  errors: any = [];
	token: any;
	success = false;

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private myAuthService: MyAuthService
	) {
		this.unsubscribe = new Subject();
	}
  /*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {

    this.token = this.route.snapshot.queryParams.token;
		if(!this.token){
      this.router.navigateByUrl('/auth/login');
    }

		this.initResetForm();
	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

  	/**
	 * Form initalization
	 * Default params, validators
	 */
	initResetForm() {
		this.resetForm = this.fb.group({
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
  }
  
  	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.resetForm.controls;

		// check form
		if (this.resetForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		// const _user: User = new User();
		let _user: any = {};
		_user.companyName = controls['password'].value;
		_user.newPassword = controls['confirmPassword'].value;
    
    this.myAuthService.forgotPasswordVerify(this.token, _user.newPassword).subscribe((response: any) => {
      if (response) {
				this.authNoticeService.setNotice(this.translate.instant("Your Password has been reset"), 'success');
				this.cdr.markForCheck();
				this.afterSuccessfulUser();
      }else {
        // this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');					AUTH.VALIDATION.INVALID_LOGIN
				this.authNoticeService.setNotice(this.translate.instant("Your Password has not been reset"), 'danger');
				this.cdr.markForCheck();
      }
      
      },
      (error) => {
        // this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');					AUTH.VALIDATION.INVALID_LOGIN
        this.authNoticeService.setNotice(this.translate.instant(error.submessage), 'danger');
        this.loading = false;
        this.cdr.markForCheck();
        this.resetForm.reset();
      });
  }
	
	afterSuccessfulUser(){
		setTimeout(() => {
			this.router.navigateByUrl('/rvmsas/dashboard');  
		  }, 5000);
	}

  /**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.resetForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
