import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SimpleTimer } from "ng2-simple-timer";
import { WidgetUtil } from "./widget.util";
import { MyAuthService } from '../../../../core/auth';
import { constants } from "../../../../app.constants";
import { MatDialog } from "@angular/material";

@Injectable()
export class SessiontimeoutUtil {
  logoutTimerSubscriptionId: string;
  startTimerId: string;

  tick: number = 1;

  constructor(private modalService: NgbModal, 
    private dialog: MatDialog,
              private timerService: SimpleTimer
              ) {
  }

  onPageChange() {
    this.tick = 1;
    this.timerService.unsubscribe(this.startTimerId);
    this.timerService.newTimer('startTime', constants.sessionTimeout.tickTime);
    this.startTimerId = this.timerService.subscribe('startTime', () => this.startLogoutTimer());
  }

  startLogoutTimer() {
    if (this.tick == 1) {
      this.unSubscribeLogoutTimer();
      if (MyAuthService.isAuthenticated()) {
        this.timerService.newTimer('logoutTime', constants.sessionTimeout.tickTime);
        this.logoutTimerSubscriptionId = this.timerService.subscribe('logoutTime', () => this.logoutTimerTick());
      }
    }
    if (this.tick >= 1) {
      this.timerService.unsubscribe(this.startTimerId);
    }
    this.tick++;
  }

  unSubscribeLogoutTimer() {
    this.timerService.unsubscribe(this.logoutTimerSubscriptionId);
  }

  public logoutTimerTick = (): void => {
    let diff: number = MyAuthService.getTokenExpiry() - new Date().valueOf();
    if (diff < 20000) {
      this.unSubscribeLogoutTimer();
      if (MyAuthService.isAuthenticated()) {
        WidgetUtil.showSessionTimeoutPopup(this.dialog);
      }
    } else if (!MyAuthService.isAuthenticated()) {
      this.unSubscribeLogoutTimer();
    }
  };
}
